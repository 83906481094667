import React from 'react';
import { ReactComponent as WecityLogo } from '../images/wecity_logo_white.svg';

function Footer() {
  return (
    <footer className="p-4 mt-4 bg-wc-blue text-white sm:p-6">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
          <a href="https://www.wecity.nl/" className="flex items-center">
            <WecityLogo />
          </a>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>

            <ul className="text-white">
              <li className="mb-4">
                <a href="https://wecity.nl/nl/over-ons" className="hover:underline">Over ons</a>
              </li>
            </ul>
          </div>
          <div>

            <ul className="text-white">
              <li className="mb-4">
                <a href="https://wecity.nl/nl/algemene-voorwaarden" className="hover:underline ">Voorwaarden</a>
              </li>
            </ul>
          </div>
          <div>

            <ul className="text-white">
              <li className="mb-4">
                <a href="https://wecity.nl/nl/privacybeleid" className="hover:underline">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-white sm:text-center">
          © 2023
          <a href="https://www.wecity.nl/" className="hover:underline pl-1">WeCity</a>
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a href="https://www.linkedin.com/company/we-city/" className="text-white hover:text-gray-900">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-5 h-5">
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              />
            </svg>
            <span className="sr-only">LinkedIn</span>
          </a>
          <a href="https://twitter.com/wecity1" className="text-white hover:text-gray-900">
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
              />
            </svg>
            <span className="sr-only">Twitter</span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
