import React, { useEffect } from 'react';
import { Table } from 'flowbite-react';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import {
  faTrashCan, faHashtag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { useAdminDeleteAssetMutation, useAdminGetAssetsForUserQuery, User } from '../../../generated/gql/types';
import Loader from '../../Loader';
import { getProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import CopyToClipboardButton from './CopyToClipboardButton';
import AssetDataLink from './AssetDataLink';

function AssetTable({ user }: AssetTableProps) {
  const { data: assets, loading, error } = useAdminGetAssetsForUserQuery({
    context: { clientName: 'asset' },
    variables: {
      userId: user.id,
    },
    fetchPolicy: 'network-only',
  });
  const { t } = useTranslation();

  const [adminDeleteAsset, {
    loading: deleteLoading, error: deleteError,
  }] = useAdminDeleteAssetMutation();

  useEffect(() => {
    if (deleteError !== null && deleteError !== undefined) {
      Sentry.captureMessage(deleteError?.message, 'error');
    }
  }, [deleteError]);

  function deleteAsset(assetId: string) {
    adminDeleteAsset({
      context: { clientName: 'asset' },
      variables: {
        assetId,
      },
      refetchQueries: [
        'AdminGetAssetsForUser',
      ],
    });
  }

  function handleDeleteConfirmation(assetId: string) {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete this sensor')}`)) {
      deleteAsset(assetId);
    }
  }

  if (loading || deleteLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p className="py-4">
        Asset fetch failed, reason:
        {error.message}
      </p>
    );
  }

  if (deleteError) {
    return (
      <p className="py-4">
        Asset deletetion failed, reason:
        {deleteError?.message ?? 'unknown'}
      </p>
    );
  }

  const assetList = assets?.adminAssetsForUser?.data;

  return (
    <div>
      {assetList !== null && assetList !== undefined && assetList.length > 0 ? (
        <Table hoverable className="p-3">
          <Table.Head>
            <Table.HeadCell>
              {t('name')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('serial')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('soil type')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('updated')}
              Updated
            </Table.HeadCell>
            <Table.HeadCell>
              {t('created')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('data')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('manage')}
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {assetList.map((asset) => (
              <Table.Row
                key={asset.id}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell>
                  {asset.name}
                </Table.Cell>
                <Table.Cell>
                  {getProperty(asset, PientereTuinenAssetProperty.SensorId)?.value ?? 'unknown'}
                </Table.Cell>
                <Table.Cell className="flex">
                  {getProperty(asset, PientereTuinenAssetProperty.SoilType)?.value ?? 'unknown'}
                </Table.Cell>
                <Table.Cell>
                  {format(parseISO(asset.updatedAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                </Table.Cell>
                <Table.Cell>
                  {format(parseISO(asset.createdAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center gap-x-2 justify-center">
                    <CopyToClipboardButton
                      content={<FontAwesomeIcon icon={faHashtag} className="cursor-pointer" />}
                      clipboardData={asset.id}
                      tooltipMessage="Copied internal id"
                    />

                    <AssetDataLink assetId={asset.id} />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex items-center justify-center gap-x-2">
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      color="red"
                      className="cursor-pointer"
                      onClick={() => handleDeleteConfirmation(asset.id)}
                    />
                  </div>
                </Table.Cell>

              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )
        : (
          <p className="py-2">No pientere tuinen assets found for user</p>
        )}

    </div>
  );
}

type AssetTableProps = {
  user: User
};

export default AssetTable;
