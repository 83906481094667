import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { OidcProvider } from '@axa-fr/react-oidc';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import App from './App';
import LoadingPage from './components/LoadingPage';
import SessionTimeoutPage from './components/SessionTimeoutPage';
import * as nl from './locales/nl/translation.json';
import * as en from './locales/en/translation.json';

const doc = document.getElementById('root');
if (doc) {
  const root = ReactDOM.createRoot(doc);
  const configuration = {
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'wecity-service-portal',
    redirect_uri: `${process.env.REACT_APP_URL}/loading`,
    scope: 'openid wecity-service-portal',
    authority: process.env.REACT_APP_KEYCLOAK_URL ?? '',
    service_worker_only: false,
    service_worker_convert_all_requests_to_cors: true,
    refresh_time_before_tokens_expiration_in_second: 240, // keycloak is configured at 300
  };

  const loading = () => <LoadingPage />;
  const sessionTimeout = () => <SessionTimeoutPage />;

  i18n
    .use(initReactI18next)
    .init({
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      lng: 'nl', // if you're using a language detector, do not define the lng option
      fallbackLng: 'en',
      resources: {
        nl: {
          translation: nl,
        },
        en: {
          translation: en,
        },
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });

  if (process.env.REACT_APP_URL !== 'http://localhost:3000') {
    Sentry.init({
      dsn: 'https://43680b61c7f9431583fe0c1227d8a125@o4504315505999872.ingest.sentry.io/4505272226283520',
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.SENTRY_RELEASE,
    });
  }

  root.render(
    <StrictMode>
      <OidcProvider
        configuration={configuration}
        loadingComponent={loading}
        authenticatingErrorComponent={sessionTimeout}
        authenticatingComponent={loading}
        callbackSuccessComponent={loading}
        sessionLostComponent={sessionTimeout}
      >
        <App />
      </OidcProvider>
    </StrictMode>,
  );
}
