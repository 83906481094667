import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
  UserSearchQuery,
  useSearchUsersLazyQuery,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import UserTable from './UserTable';

function UserManagementApp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register } = useForm<UserSearchQuery>();
  const [search, {
    data: searchData, loading: searchLoading, error: searchError,
  }] = useSearchUsersLazyQuery();

  let timer: NodeJS.Timeout | null = null;

  const submitSearch = (searchString: string) => {
    if (searchString.length < 3) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchParams({ searchString });
    }, 500);
  };

  useEffect(() => {
    const query = searchParams.get('searchString');
    if (query) {
      search({
        fetchPolicy: 'network-only',
        variables: {
          query: {
            searchString: query,
          },
        },
      });
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full md:w-1/2 text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          User management
        </h2>

        <h3 className="mb-4 text-3xl w-full md:w-1/2">Search users</h3>
        <p>
          The search query is matched against the firstname, lastname and email of a user
        </p>
      </div>

      <form className="flex flex-col gap-4" onSubmit={(e) => { e.preventDefault(); }}>
        <div>
          <input
            type="text"
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mb-5"
            placeholder="Search minimal 3 characers"
            {...register('searchString', {
              required: true,
              onChange: (e) => {
                submitSearch(e.target.value);
              },
              value: searchParams.get('searchString') ?? '',
            })}
          />
        </div>
      </form>

      { searchLoading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          {(searchError && (
            <div className="pb-2">
              <p className="font-bold">Oops something went wrong: </p>
              <p>{searchError.message}</p>
            </div>
          ))}

          <UserTable userList={searchData?.userSearch ?? []} />
        </div>
      ) }

    </div>
  );
}

export default UserManagementApp;
