import React from 'react';
import { Badge } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

function StatusBadge({ status }: StatusBadgeProps) {
  const { t } = useTranslation();

  if (status === 'live') {
    return (
      <div className="flex">
        <Badge color="success">
          <div className="capitalize inline">{t(status)}</div>
        </Badge>
      </div>
    );
  }

  return (
    <div className="flex">
      <Badge color="gray">
        <div className="capitalize inline">{t(status)}</div>
      </Badge>
    </div>
  );
}

type StatusBadgeProps = {
  status: string
};

export default StatusBadge;
