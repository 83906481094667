import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'flowbite-react';
import { PaginationMeta } from '../../../generated/gql/types';

function Pagination({
  paginationMeta,
  paginateNext,
  paginatePrevious,
  paginateToPage,
  numberOfRows,
  onNumberOfRowsChange,
}: PaginationProps) {
  const { t } = useTranslation();

  const paginationOptions: DropdownOption[] = [
    {
      name: '10',
      value: 10,
    },
    {
      name: '20',
      value: 20,
    },
    {
      name: '50',
      value: 50,
    },
    {
      name: '100',
      value: 100,
    },
  ];

  const updateNumberOfRows = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value && onNumberOfRowsChange !== undefined) {
      onNumberOfRowsChange(parseInt(event.target.value, 10));
    }
  };

  function renderPaginationOptions() {
    return (
      <Select
        value={numberOfRows}
        onChange={updateNumberOfRows}
      >
        {paginationOptions.map((o) => (
          <option key={`option${o.value}`} value={o.value}>{o.name}</option>
        ))}
      </Select>
    );
  }

  const separator = (
    <div
      className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
    >
      ...
    </div>
  );

  function renderPreviousButton() {
    if (paginationMeta?.currentPage === 1) {
      return (
        <button
          type="button"
          disabled={(paginationMeta?.currentPage ?? 0) <= 1}
          className="relative inline-flex items-center p-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700"
        >
          <span className="opacity-20">{t('previous')}</span>
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={(paginationMeta?.currentPage ?? 0) <= 1}
        onClick={() => paginatePrevious()}
        className="relative inline-flex items-center p-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        <span>{t('previous')}</span>
      </button>
    );
  }

  function renderNextButton() {
    if ((paginationMeta?.currentPage ?? 0) >= (paginationMeta?.lastPage ?? 0)) {
      return (
        <button
          type="button"
          disabled={(paginationMeta?.currentPage ?? 0) >= (paginationMeta?.lastPage ?? 0)}
          className="relative inline-flex items-center p-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700"
        >
          <span className="opacity-20">{t('next')}</span>
        </button>
      );
    }

    return (
      <button
        type="button"
        onClick={() => paginateNext()}
        className="relative inline-flex items-center p-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        <span>{t('next')}</span>
      </button>
    );
  }

  function renderPageNumbers() {
    const extraPageList = Array.from(Array(Math.ceil((paginationMeta?.total ?? 40) / (numberOfRows ?? 10))).keys());

    extraPageList.pop();
    extraPageList.shift();

    if (extraPageList.length <= 0) {
      return '';
    }

    return (
      <>
        {extraPageList.map((page) => (
          ((page + 1) === paginationMeta?.currentPage) ? (
            <button
              key={`pnc${page + 1}`}
              type="button"
              onClick={() => paginateToPage(page + 1)}
              className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
            >
              <span>{page + 1}</span>
            </button>
          ) : (
            <button
              key={`pnd${page + 1}`}
              type="button"
              onClick={() => paginateToPage(page + 1)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>{page + 1}</span>
            </button>
          )

        ))}
      </>
    );
  }

  function renderFirstPage() {
    if (paginationMeta?.total !== undefined && paginationMeta?.perPage !== undefined && paginationMeta?.currentPage !== undefined) {
      return paginationMeta?.currentPage === 1 ? (
        <button
          type="button"
          disabled={paginationMeta?.currentPage === 1}
          className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
        >
          <span>1</span>
        </button>
      ) : (
        <button
          type="button"
          onClick={() => paginateToPage(1)}
          className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <span>1</span>
        </button>
      );
    }
    return '';
  }

  function renderLastPage() {
    if (paginationMeta?.total !== undefined && paginationMeta?.perPage !== undefined && paginationMeta?.total > paginationMeta?.perPage && paginationMeta?.currentPage !== undefined) {
      if (paginationMeta?.currentPage === paginationMeta?.lastPage) {
        return (
          <button
            type="button"
            disabled={paginationMeta.currentPage === paginationMeta.lastPage}
            className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
          >
            <span>
              {paginationMeta?.lastPage ? (
                paginationMeta?.lastPage
              ) : <>&infin;</>}
            </span>
          </button>
        );
      }

      return (
        <button
          type="button"
          onClick={() => paginateToPage(paginationMeta.lastPage ?? 1)}
          className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <span>
            {paginationMeta?.lastPage ? (
              paginationMeta?.lastPage
            ) : <>&infin;</>}
          </span>
        </button>
      );
    }

    return '';
  }

  function renderActualPageBlob() {
    if (paginationMeta != null && paginationMeta?.currentPage !== undefined && paginationMeta?.lastPage !== undefined && paginationMeta?.currentPage !== null && paginationMeta?.lastPage !== null) {
      if (paginationMeta?.currentPage === 1 || paginationMeta?.currentPage === paginationMeta.lastPage) {
        return (
          <>
            <button
              type="button"
              onClick={() => paginateToPage(2)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>2</span>
            </button>
            {separator}
            <button
              type="button"
              onClick={() => paginateToPage(paginationMeta.lastPage! - 1)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>{(paginationMeta?.lastPage ?? 1) - 1}</span>
            </button>
          </>
        );
      }

      if (paginationMeta?.currentPage === paginationMeta.lastPage! - 1) {
        return (
          <>
            {separator}
            <button
              type="button"
              onClick={() => paginateToPage(paginationMeta.lastPage! - 3)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>{paginationMeta.lastPage - 3}</span>
            </button>
            <button
              type="button"
              onClick={() => paginateToPage(paginationMeta.lastPage! - 2)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>{paginationMeta.lastPage - 2}</span>
            </button>
            <button
              type="button"
              onClick={() => paginateToPage(paginationMeta.lastPage! - 1)}
              className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
            >
              <span>{paginationMeta.lastPage - 1}</span>
            </button>
          </>
        );
      }

      if (paginationMeta?.currentPage === 2) {
        return (
          <>
            <button
              type="button"
              onClick={() => paginateToPage(2)}
              className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
            >
              <span>2</span>
            </button>
            <button
              type="button"
              onClick={() => paginateToPage(3)}
              className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              <span>3</span>
            </button>
            {separator}
          </>
        );
      }

      if (paginationMeta?.currentPage >= 3 && paginationMeta?.currentPage < paginationMeta.lastPage) {
        const actualPageBlob = [paginationMeta.currentPage - 2, paginationMeta.currentPage - 1, paginationMeta.currentPage];

        return (
          <>
            {separator}
            {actualPageBlob.map((page) => (
              ((page + 1) === paginationMeta?.currentPage) ? (
                <button
                  key={`pna${page + 1}`}
                  type="button"
                  onClick={() => paginateToPage(page + 1)}
                  className="relative inline-flex items-center p-2 border bg-wc-blue text-sm font-medium text-white"
                >
                  <span>{page + 1}</span>
                </button>
              ) : (
                <button
                  key={`pnb${page + 1}`}
                  type="button"
                  onClick={() => paginateToPage(page + 1)}
                  className="relative inline-flex items-center p-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  <span>{page + 1}</span>
                </button>
              )

            ))}
            {separator}
          </>
        );
      }
    }
    return '';
  }

  return (
    <div className="pagination row container flex py-2">
      {paginationMeta?.lastPage !== null && paginationMeta?.lastPage !== undefined && paginationMeta?.lastPage <= 10 ? (

        <nav
          className="flex-row relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {renderPreviousButton()}
          {renderFirstPage()}

          {renderPageNumbers()}

          {renderLastPage()}
          {renderNextButton()}
        </nav>
      ) : (
        <nav
          className="flex-row relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          {renderPreviousButton()}
          {renderFirstPage()}

          {renderActualPageBlob()}

          {renderLastPage()}
          {renderNextButton()}

        </nav>
      )}

      {onNumberOfRowsChange !== undefined && (
        <div className="relative z-0 inline-flex px-4">
          <span className="align-middle p-2 text-md text-gray-700">
            {t('number of rows')}
            :
          </span>
          {renderPaginationOptions()}
        </div>
      )}
    </div>
  );
}

type PaginationProps = {
  paginationMeta: PaginationMeta | undefined | null
  paginateNext: () => void
  paginatePrevious: () => void
  paginateToPage: (page: number) => void
  numberOfRows: number | undefined
  onNumberOfRowsChange: undefined | ((value: number) => void);
};

type DropdownOption = {
  name: string
  value: number
};

export default Pagination;
