import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  useGetUserDetailsQuery, User,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import AssetTable from './AssetTable';

function UserAssets() {
  const params = useParams();
  const navigate = useNavigate();

  const { data: userData, loading: userDataLoading, error: userDataError } = useGetUserDetailsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        id: params.userId ?? '',
      },
    },
  );

  if (userDataError) {
    return <div>{userDataError.message}</div>;
  }

  if (!userData?.user || userDataLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="text-xl flex gap-x-1">
        <div>Assets for user</div>
        <div className="capitalize-first font-bold">
          {userData.user.firstname}
        </div>
        <div className="capitalize-first font-bold">
          {userData.user.lastname}
        </div>
        <div className="font-bold">
          (
          {userData.user.email}
          )
        </div>
      </div>
      <Button type="button" color="info" className="btn p-0 mb-4" onClick={() => navigate(-1)}>
        <FontAwesomeIcon
          icon={icon({ name: 'arrow-left', family: 'classic', style: 'regular' })}
          size="xl"
        />
      </Button>
      <AssetTable user={userData?.user as User} />
    </div>
  );
}

export default UserAssets;
