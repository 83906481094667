import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Label, Table, TextInput,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import Select, { MultiValue } from 'react-select';

import { SingleValue } from 'react-select/dist/declarations/src/types';
import {
  ImportHubspotListCommand, RoleOrganization,
  RoleUser,
  useGetHubspotContactListsQuery, useGetOrganizationsQuery,
  useImportHubspotListMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function ImportHubspotContactListApp() {
  const { data: organizationsData, loading: organizationsLoading } = useGetOrganizationsQuery();
  const { register, handleSubmit, setValue } = useForm<ImportHubspotListCommand>(
    {
      defaultValues: {
        userRoles: [],
        organizationRoles: [],
      },
    },
  );
  const [importList, { data, loading, error }] = useImportHubspotListMutation();
  const {
    data: contactLists,
    loading: contactListsLoading,
    error: contactListsError,
  } = useGetHubspotContactListsQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();

  const onSubmit = handleSubmit((importCommand) => {
    if (importCommand.listId === '0') {
      return;
    }

    importList({
      variables: {
        command: importCommand,
      },
    });
  });

  function setUserRoles(e: MultiValue<{ label: string, value: RoleUser }>) {
    setValue('userRoles', e.map((v) => v.value));
  }

  function setOrganizationRoles(e: MultiValue<{ label: string, value: RoleOrganization }>) {
    setValue('organizationRoles', e.map((v) => v.value));
  }

  function setContactList(e: SingleValue<{ label: string, value: number }>) {
    if (e) {
      setValue('listId', e.value.toString());
    }
  }

  function setOrganization(e: SingleValue<{ label: string, value: string }>) {
    if (e) {
      setValue('orgId', e.value);
    }
  }

  if (contactListsLoading || organizationsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full md:w-1/2 text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          {t('hubspot management')}
        </h2>

        <h3 className="mb-4 text-3xl w-full md:w-1/2">{t('import hubspot list')}</h3>
        <p>
          {t('hubspot contact list import description')}
        </p>
        <a className="text-wc-blue underline" href="https://app.hubspot.com/contacts/8659463/objectLists/all" target="_blank" rel="noreferrer">Hubspot lists page</a>
      </div>

      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <div>
          {contactLists && contactLists?.hubspot?.contactLists?.length > 0 && !contactListsError ? (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="listId"
                  value={`${t('select contact list')}`}
                />
              </div>
              <Select
                required
                isSearchable
                placeholder="Search contact list"
                isLoading={loading}
                options={contactLists?.hubspot?.contactLists?.map((l) => ({
                  label: `${l.name} (${l.contactCount})`,
                  value: l.id,
                }))}
                className="w-full"
                classNamePrefix="select"
                onChange={(e) => setContactList(e)}
              />
            </>
          ) : (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="listId"
                  value={`${t('no list found')}`}
                />
              </div>
              <TextInput
                type="text"
                placeholder="12345"
                required
                {...register('listId')}
              />
            </>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="orgId"
              value={`${t('select organization')}`}
            />
          </div>
          <Select
            required
            isSearchable
            placeholder="Search organization"
            isLoading={loading}
            options={organizationsData?.organizations?.map((o) => ({
              label: o.orgId,
              value: o.orgId,
            }))}
            className="w-full"
            classNamePrefix="select"
            onChange={(e) => setOrganization(e)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="role"
              value="Select a role"
            />
          </div>
          <Select
            isMulti
            options={Object.values(RoleUser).map((role) => ({
              label: role.toString(),
              value: role,
            }))}
            className="w-full"
            classNamePrefix="select"
            onChange={(e: MultiValue<{ label: string, value: RoleUser }>) => setUserRoles(e)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="role"
              value="Select an organization role"
            />
          </div>
          <Select
            isMulti
            options={Object.values(RoleOrganization).map((role) => ({
              label: role.toString(),
              value: role,
            }))}
            className="w-full"
            classNamePrefix="select"
            onChange={(e: MultiValue<{ label: string, value: RoleOrganization }>) => setOrganizationRoles(e)}
          />
        </div>

        {loading ? (
          <Button type="submit" disabled>
            Import
          </Button>
        ) : (
          <Button type="submit">
            Import
          </Button>
        )}
      </form>

      { loading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          {(error && (
            <div className="py-4">
              <p className="font-bold">Oops something went wrong: </p>
              <p>{error.message}</p>
            </div>
          ))}

          {((data && data.hubspot.importList.errorMessages.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">Errors</h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    Message
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  { data.hubspot.importList.errorMessages.map((message) => (
                    <Table.Row key={message} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {message}
                      </Table.Cell>
                    </Table.Row>
                  )) }
                </Table.Body>
              </Table>
            </>
          ))}

          {((data && data.hubspot.importList.importedUsers.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">
                Imported users (
                {data.hubspot.importList.importedUsers.length}
                )
              </h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    {t('first name')}
                  </Table.HeadCell>
                  <Table.HeadCell>
                    {t('last name')}
                  </Table.HeadCell>
                  <Table.HeadCell>
                    {t('email')}
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  { data.hubspot.importList.importedUsers.map((user) => (
                    <Table.Row key={user.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {user.firstname}
                      </Table.Cell>
                      <Table.Cell>
                        {user.lastname}
                      </Table.Cell>
                      <Table.Cell>
                        {user.email}
                      </Table.Cell>
                    </Table.Row>
                  )) }
                </Table.Body>
              </Table>
            </>
          ))}

          {(data && data.hubspot.importList.importedUsers.length <= 0 && (
            <p className="py-4">No users have been imported</p>
          ))}
        </div>
      ) }

    </div>
  );
}

export default ImportHubspotContactListApp;
