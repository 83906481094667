import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretUp, faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Direction, Sort } from '../../hooks/useRegisteredSensorAssets';

function ColumnSortButton({ sort, onChange }: ColumnSortButtonProps) {
  return (
    <div>
      {sort.direction === Direction.DESC ? (
        <FontAwesomeIcon icon={faCaretDown} onClick={() => onChange({ ...sort, direction: Direction.ASC })} className="cursor-pointer fa-xl" />
      ) : (
        <FontAwesomeIcon icon={faCaretUp} onClick={() => onChange({ ...sort, direction: Direction.DESC })} className="cursor-pointer fa-xl" />
      )}
    </div>
  );
}

type ColumnSortButtonProps = {
  sort: Sort
  onChange: (sort: Sort) => void
};

export default ColumnSortButton;
