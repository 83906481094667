import React from 'react';
import {
  Alert,
  Button, TextInput,
} from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import {
  UpdateUserCommand,
  useGetUserDetailsQuery,
  useSendEmailVerificationForUserMutation,
  useUpdateUserMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function UserEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: userData, loading: userDataLoading, error: userDataError } = useGetUserDetailsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        id: params.userId ?? '',
      },
    },
  );

  const [sendEmail, {
    data: sendEmailResponse,
    loading: sendEmailLoading,
    error: sendEmailError,
  }] = useSendEmailVerificationForUserMutation();

  const [updateUser, {
    data: updateUserResponse,
    loading: updateUserLoading,
    error: updateUserError,
  }] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
  } = useForm<UpdateUserCommand>({});

  const onSubmit = handleSubmit((updateCommand) => {
    updateUser({
      variables: {
        command: updateCommand,
      },
    });
  });

  function sendEmailForUser() {
    if (userData?.user?.id) {
      sendEmail({
        variables: {
          userId: userData?.user?.id,
        },
      });
    }
  }

  function handleResendConfirmation() {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to resend the verification email')}`)) {
      sendEmailForUser();
    }
  }

  if (userDataError) {
    return <div>{userDataError.message}</div>;
  }

  if (!userData?.user || userDataLoading) {
    return (
      <Loader />
    );
  }

  return (
    <form className="flex flex-col gap-y-2 justify-center mx-auto max-w-4xl" onSubmit={onSubmit}>
      <input type="hidden" {...register('id', { value: userData?.user.id })} />
      <div className="text-xl flex gap-x-1">
        <div>Editing user</div>
        <div className="capitalize-first font-bold">
          {userData.user.firstname}
        </div>
        <div className="capitalize-first font-bold">
          {userData.user.lastname}
        </div>
        <div className="font-bold">
          (
          {userData.user.email}
          )
        </div>
      </div>
      <Button type="button" color="info" className="btn p-0" onClick={() => navigate(-1)}>
        <FontAwesomeIcon
          icon={icon({ name: 'arrow-left', family: 'classic', style: 'regular' })}
          size="xl"
        />
      </Button>

      <div className="flex items-center gap-x-2">
        <div className="text-sm text-gray-500 dark:text-gray-400">
          User created on
          {' '}
          {format(parseISO(userData?.user.createdAt), "dd-MM-y 'at' HH:mm", { locale: nl })}
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="text-sm text-gray-500 dark:text-gray-400">
          User updated on
          {' '}
          {format(parseISO(userData?.user.updatedAt), "dd-MM-y 'at' HH:mm", { locale: nl })}
        </div>
      </div>
      <div className="flex-col grid grid-cols-1 gap-2 sm:grid-cols-2">
        <TextInput
          id="firstname"
          placeholder=""
          type="text"
          disabled
          required
          {...register('firstname', { value: userData?.user.firstname ?? '' })}
        />
        <TextInput
          id="lastname"
          placeholder=""
          type="text"
          disabled
          required
          {...register('lastname', { value: userData?.user.lastname ?? '' })}
        />
      </div>
      <div className="flex gap-x-2 w-full items-center text-sm text-gray-500 dark:text-gray-400">
        <TextInput
          id="email"
          placeholder=""
          required
          type="text"
          className="w-full"
          {...register('email', { value: userData?.user.email })}
        />
        {' '}
        {userData?.user.isEmailVerified ? (<FontAwesomeIcon icon={faCircleCheck} color="green" />) : ('')}
      </div>

      <div className="flex-col space-y-2">
        {sendEmailError && (
        <Alert
          color="failure"
        >
          <span>
            <span className="font-medium">
              Email send failed!
            </span>
            {' '}
            {sendEmailError?.message}
          </span>
        </Alert>
        )}

        {updateUserError && (
        <Alert
          color="failure"
        >
          <span>
            <span className="font-medium">
              Updating user failed!
            </span>
            {' '}
            {updateUserError?.message}
          </span>
        </Alert>
        )}

        {updateUserResponse && (
        <Alert
          color="success"
        >
          <span>
            <span className="font-medium">
              Updating user succeeded,
            </span>
            {' '}
            changed email to
            {' '}
            <span className="font-bold">{updateUserResponse.users.updateUser.email}</span>
          </span>
        </Alert>
        )}

        {sendEmailResponse?.users.sendEmailVerification && (
        <Alert
          color="success"
        >
          <span>
            <span className="font-medium">
              Send email verification successfully
            </span>
          </span>
        </Alert>
        )}

        <div className="flex-col space-y-2">
          {sendEmailLoading || !userData?.user?.id || sendEmailError ? (
            <Button type="button" color="warning" className="p-0" disabled>
              Resend verification email
            </Button>
          ) : (
            <Button type="button" color="warning" className="p-0" onClick={() => handleResendConfirmation()}>
              Resend verification email
            </Button>
          )}

          {updateUserLoading || updateUserError ? (
            <Button type="submit" color="info" className="btn p-0" disabled>
              Save changes
            </Button>
          ) : (
            <Button type="submit" color="info" className="btn p-0">
              Save changes
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}

export default UserEdit;
