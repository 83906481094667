import { TFunction } from 'i18next';
import { LatLng } from 'leaflet';
import { Asset, Property } from './generated/gql/types';
import { DropdownOption } from './components/modules/pientere-tuinen/Dropdown';

export enum PientereTuinenAssetProperty {
  GardenSize = 'garden_size',
  GardenOrientation = 'garden_orientation',
  GardenHardening = 'garden_hardening',
  SoilType = 'soil',
  Coordinates = 'location',
  SensorId = 'sensor_id',
  OrganizationId = 'organization_id',
  UserId = 'user',
}

export enum RegisteredSensorAssetProperty {
  Goal = 'goal',
  Title = 'title',
  Coordinates = 'coordinates',
  SensorType = 'sensor_type',
  Theme = 'theme',
  ProcessesPersonalData = 'processes_personal_data',
  IsMobile = 'is_mobile',
  Status = 'status',
  ValidUntil = 'valid_until',
  LegislationUrl = 'legislation_url',
  ContactName = 'contact_name',
  ContactEmail = 'contact_email',
  Organization = 'operating_organization',
  Location = 'location',
  SensorReferenceId = 'sensor_reference_id',
}

export function getProperty(
  asset: Asset,
  property: PientereTuinenAssetProperty | RegisteredSensorAssetProperty,
): Property | undefined {
  return asset.properties?.find((p) => p.key === property);
}

export function validationStringToOptionList(validationString: string, t?: TFunction): DropdownOption[] {
  return validationString.replace('in:', '').split(',').map((item) => (
    {
      name: t ? t(item.replaceAll('_', ' ')) : item.replaceAll('_', ' '),
      value: item,
    }
  ));
}

export function latLongFromProperty(property: Property | undefined): LatLng | undefined {
  if (property !== undefined && property.value) {
    const ll = property.value.split(',');

    if (ll[0] && !Number.isNaN(Number(ll[0])) && ll[1] && !Number.isNaN(Number(ll[1]))) {
      return new LatLng(Number(ll[0]), Number(ll[1]));
    }
  }

  return undefined;
}
