import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganizationWithUsersQuery, UserSummary } from '../../../generated/gql/types';
import Loader from '../../Loader';
import UserTable from './UserTable';

function OrganizationUsersApp() {
  const params = useParams();
  const { data, loading } = useGetOrganizationWithUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: params.orgId ?? '',
    },
  });

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          Organization
          {' '}
          {params.orgId}
          {' '}
          Users management
        </h2>
      </div>

      { loading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          <UserTable userList={data?.organization.users?.map((i) => i.user as UserSummary) ?? []} />
        </div>
      ) }
    </div>
  );
}

export default OrganizationUsersApp;
