import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Loader from '../../Loader';
import { SRRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';
import useRegisteredSensorAssets from '../../hooks/useRegisteredSensorAssets';
import useMyRoles from '../../hooks/useMyRoles';
import { RoleOrganization } from '../../../generated/gql/types';
import ErrorCard from '../../ErrorCard';

function RegisteredSensorCard({ orgId }: RegisteredSensorCardProps) {
  const { data, loading, error } = useRegisteredSensorAssets({ orgId, filters: [] });
  const { data: liveData, loading: liveLoading, error: liveError } = useRegisteredSensorAssets({ orgId, filters: [{ key: 'status', value: 'live' }] });
  const myRoles = useMyRoles();
  const pinIconStyle = {
    '--fa-primary-color': '#12678F',
    '--fa-secondary-color': '#A6C5D4',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;

  const { t } = useTranslation();

  if (loading || liveLoading) {
    return <Loader />;
  }

  if (error || liveError) {
    return <ErrorCard moduleName="Sensor Register" />;
  }

  return (
    <div className="wccard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left text-2xl font-bold text-gray-900">
            {t('manage sensor registry')}
            {' '}
            <div className="capitalize-first">{orgId}</div>
          </h4>
          <FontAwesomeIcon
            className="h-[60px] self-center inline-right"
            icon={icon({ name: 'map-location-dot', family: 'classic', style: 'duotone' })}
            style={pinIconStyle}
          />
        </div>

        <div className="h-full p-5 mb-5">
          <div className="rounded-lg p-5 mb-2 border border-gray-300">
            <div className="text-gray-900">
              <h5 className="text-2xl font-bold text-left text-gray-900">
                {t('all sensors')}
              </h5>
              <div className="text-[5.5rem] leading-[6rem] text-center font-extrabold">
                {data?.registeredSensorAssets?.meta?.total}
              </div>
            </div>
          </div>

          <div className="rounded-lg p-5 mb-2 border border-gray-300">
            <div className="text-gray-900">
              <h5 className="text-2xl font-bold text-left text-gray-900">
                {t('live sensors')}
              </h5>
              <div className="text-[5.5rem] leading-[6rem] text-center font-extrabold">
                {liveData?.registeredSensorAssets?.meta?.total}
              </div>
            </div>
          </div>
        </div>

        <Link
          to={SRRoute(orgId)}
          className="btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            {(myRoles?.organizationRoles ?? []).find((r) => r.orgId === orgId)?.roles.find((r) => r === RoleOrganization.OrgSensorRegisterManager)
              ? (t('register sensors')) : (t('view sensors'))}
            <ArrowRight />
          </span>
        </Link>

      </div>
    </div>
  );
}

type RegisteredSensorCardProps = {
  orgId: string
};

export default RegisteredSensorCard;
