import React from 'react';
import {
  Table,
} from 'flowbite-react';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UserSummary,
} from '../../../generated/gql/types';
import { UserAssetsRoute, UserRolesRoute, UserRoute } from '../../Routes';

function UserTable({ userList }: UserTableProps) {
  return (
    <div>
      {userList.length > 0 ? (
        <>
          <h2 className="pb-2 text-2xl font-bold">
            Found users (
            {userList.length}
            )
          </h2>
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>
                Firstname
              </Table.HeadCell>
              <Table.HeadCell>
                Lastname
              </Table.HeadCell>
              <Table.HeadCell>
                Email
              </Table.HeadCell>
              <Table.HeadCell>
                Updated
              </Table.HeadCell>
              <Table.HeadCell>
                Created
              </Table.HeadCell>
              <Table.HeadCell>
                Edit
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              { userList.map((user) => (
                <Table.Row key={user.id} className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer">
                  <Table.Cell>
                    {user.firstname}
                  </Table.Cell>
                  <Table.Cell>
                    {user.lastname}
                  </Table.Cell>
                  <Table.Cell>
                    {user.email}
                  </Table.Cell>
                  <Table.Cell>
                    {format(parseISO(user.updatedAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                  </Table.Cell>
                  <Table.Cell>
                    {format(parseISO(user.createdAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex gap-x-4">
                      <Link to={UserRoute(user.id)}>
                        <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-edit' })} />
                      </Link>
                      <Link to={UserRolesRoute(user.id)}>
                        <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-tag' })} />
                      </Link>
                      <Link to={UserAssetsRoute(user.id)}>
                        <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-chart' })} />
                      </Link>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )) }
            </Table.Body>
          </Table>
        </>
      ) : (
        <p className="py-2">No users found</p>
      )}
    </div>
  );
}

type UserTableProps = {
  userList: UserSummary[]
};

export default UserTable;
