import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue } from 'react-select';
import { useParams } from 'react-router-dom';
import {
  PropertyType,
  useGetRegisteredSensorSchemeQuery,
} from '../../../generated/gql/types';
import { RegisteredSensorAssetProperty, validationStringToOptionList } from '../../../AssetHelpers';
import { Filter } from '../../hooks/useRegisteredSensorAssets';
import { WeCityOrganizationIdHeader } from '../../../Global';

function FilterDropdown({ selectedFilters, onChangeHandler }: UpdateStatusDropdownProps) {
  const { t } = useTranslation();
  const params = useParams();
  const enabledFilters = [RegisteredSensorAssetProperty.Status, RegisteredSensorAssetProperty.Theme, RegisteredSensorAssetProperty.SensorType];
  const {
    data,
    loading,
  } = useGetRegisteredSensorSchemeQuery({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  function toFilters(propertyType: PropertyType): Filter[] {
    if (!propertyType.validation || !propertyType.name) {
      return [];
    }

    return validationStringToOptionList(propertyType.validation, t).map((option) => ({
      key: propertyType.name!,
      value: option.value,
    } as Filter));
  }

  const filters = enabledFilters
    .map((f) => data?.scheme?.data?.propertyTypes?.find((p) => p.name === f))
    .filter((type) => type !== undefined)
    .flatMap((type) => toFilters(type as PropertyType));

  function toLabel(f: Filter): string {
    return `${t(f.key.replaceAll('_', ' '))}: ${t(f.value.replaceAll('_', ' '))}`;
  }

  return (
    <Select
      defaultValue={selectedFilters.map((f) => ({
        label: toLabel(f),
        value: f,
      }
      ))}
      isMulti
      placeholder="Filter"
      isLoading={loading}
      options={filters.map((f) => ({
        label: toLabel(f),
        value: f,
      })).filter((optionFilter) => !selectedFilters.find((selectedFilter) => toLabel(selectedFilter) === toLabel(optionFilter.value)))}
      className="w-full mt-6"
      classNamePrefix="select"
      onChange={(e) => onChangeHandler(e)}
    />
  );
}

type UpdateStatusDropdownProps = {
  selectedFilters: Filter[]
  onChangeHandler: (value: MultiValue<{ label: string, value: Filter }>) => void
};

export default FilterDropdown;
