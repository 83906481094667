import React from 'react';
import {
  Alert,
  Button,
  Modal,
} from 'flowbite-react';
import Select, { MultiValue } from 'react-select';
import { useForm } from 'react-hook-form';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import {
  RoleOrganization,
  SetOrganizationRolesCommand,
  useGetOrganizationsQuery,
  useSetOrganizationRolesMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function OrganizationRolesModal({
  userId, orgId, currentRoles, closeModalHandler,
}: OrganizationRolesModalProps) {
  const {
    data: organizationsData, loading: organizationsLoading,
  } = useGetOrganizationsQuery({ fetchPolicy: 'network-only' });

  const {
    setValue,
    handleSubmit,
  } = useForm<SetOrganizationRolesCommand>({
    defaultValues: {
      roles: [],
      userId,
      orgId,
    },
  });

  const [setOrganizationRoles, {
    data: organizationsRolesSubmitData,
    loading: organizationsRolesSubmitLoading,
    error: organizationsRolesSubmitError,
  }] = useSetOrganizationRolesMutation();

  const onOrganizationRolesSubmit = handleSubmit((setOrganizationRolesCommand) => {
    setOrganizationRoles({
      variables: {
        command: setOrganizationRolesCommand,
      },
    });
  });

  function setRolesForOrganizationRoles(e: MultiValue<{ label: string, value: RoleOrganization }>) {
    setValue('roles', e.map((v) => v.value));
  }

  function setOrganization(e: SingleValue<{ label: string, value: string }>) {
    setValue('orgId', e?.value ?? '');
  }

  function renderModal() {
    if (organizationsLoading) {
      return (
        <Loader />
      );
    }

    return (
      <form className="max-w-2xl flex flex-col min-w-[300px] gap-y-4 min-h-[500px]" onSubmit={onOrganizationRolesSubmit}>
        <div className="font-bold mt-4 text-gray-500 dark:text-gray-400 text-center">
          {orgId !== undefined ? (
            <div>
              Assign the roles to the organization
              {' '}
              {orgId}
            </div>
          ) : (
            <div>Select an organization and assign the roles</div>
          )}
        </div>
        <Select
          isDisabled={orgId !== undefined}
          required
          isSearchable
          placeholder="Search organization"
          isLoading={organizationsLoading}
          defaultValue={{
            label: orgId ?? '',
            value: orgId ?? '',
          }}
          options={organizationsData?.organizations?.map((o) => ({
            label: o.orgId,
            value: o.orgId,
          }))}
          className="w-full"
          classNamePrefix="select"
          onChange={(e) => setOrganization(e)}
        />
        <Select
          defaultValue={currentRoles.map((role) => ({
            label: role.toString(),
            value: role,
          }
          ))}
          isMulti
          options={Object.values(RoleOrganization).map((role) => ({
            label: role.toString(),
            value: role,
          }))}
          className="w-full"
          classNamePrefix="select"
          onChange={(e) => setRolesForOrganizationRoles(e)}
        />

        {organizationsRolesSubmitError && (
          <Alert
            color="failure"
          >
            <span>
              <span className="font-medium">
                Setting organization roles failed!
              </span>
              {' '}
              {organizationsRolesSubmitError?.message}
            </span>
          </Alert>
        )}

        {organizationsRolesSubmitData && (
          <Alert
            color="success"
          >
            <span>
              <span className="font-medium">
                Assigned roles
                {' '}
                {organizationsRolesSubmitData.users.setOrganizationRoles.map((r) => r.toString()).join(', ')}
                {' '}
                to organization
                {' '}
                {orgId}
              </span>
            </span>
          </Alert>
        )}

        <div className="flex justify-center mt-4 md:mt-4">
          {organizationsRolesSubmitLoading || organizationsRolesSubmitError ? (
            <Button type="submit" color="info" className="btn p-0" disabled>
              Save organization roles
            </Button>
          ) : (
            <Button type="submit" color="info" className="btn p-0">
              Save organization roles
            </Button>
          )}
        </div>
      </form>
    );
  }

  return (
    <Modal
      dismissible
      show
      size="5xl"
      onClose={closeModalHandler}
    >
      <Modal.Header>
        Update Organization Roles
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <div className="flex flex-col items-center pb-10 gap-y-1">
            {renderModal()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

type OrganizationRolesModalProps = {
  userId: string,
  currentRoles: RoleOrganization[],
  orgId?: string,
  closeModalHandler: () => void
};

export default OrganizationRolesModal;
