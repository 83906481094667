import React, { useEffect, useState } from 'react';
import {
  Alert, Badge,
  Button, Table,
} from 'flowbite-react';
import Select, { MultiValue } from 'react-select';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  RoleOrganization,
  RoleUser, SetUserRolesCommand,
  useGetUserDetailsQuery,
  useSetUserRolesMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import OrganizationRolesModal from './OrganizationRolesModal';

function UserRolesEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedOrganization, setSelectedOrganization] = useState<{ orgId: string | undefined, organizationRoles: RoleOrganization[] } | undefined>(undefined);

  const {
    data: userData, loading: userDataLoading, error: userDataError, refetch: refetchUserData,
  } = useGetUserDetailsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        id: params.userId ?? '',
      },
    },
  );

  const {
    register: userRoleRegister,
    setValue: userRoleSetValue,
    handleSubmit: userRoleHandleSubmit,
  } = useForm<SetUserRolesCommand>({});

  const [setUserRoles, {
    loading: setUserRolesLoading,
    error: setUserRolesError,
  }] = useSetUserRolesMutation();

  const onUserRoleSubmit = userRoleHandleSubmit((setUserRolesCommand) => {
    setUserRoles({
      variables: {
        command: setUserRolesCommand,
      },
    });
  });

  function setRolesForUserRoles(e: MultiValue<{ label: string, value: RoleUser }>) {
    userRoleSetValue('roles', e.map((v) => v.value));
  }

  useEffect(() => {
    refetchUserData();
  }, [selectedOrganization]);

  if (userDataError) {
    return <div>{userDataError.message}</div>;
  }

  if (!userData?.user || userDataLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div>
      {selectedOrganization !== undefined ? (
        <OrganizationRolesModal
          userId={userData?.user.id}
          orgId={selectedOrganization.orgId}
          currentRoles={selectedOrganization.organizationRoles}
          closeModalHandler={() => setSelectedOrganization(undefined)}
        />
      ) : ''}
      <div className="flex flex-col justify-center mx-auto max-w-4xl">
        <div className="text-xl flex gap-x-1">
          <div>Editing roles for user</div>
          <div className="capitalize-first font-bold">
            {userData.user.firstname}
          </div>
          <div className="capitalize-first font-bold">
            {userData.user.lastname}
          </div>
          <div className="font-bold">
            (
            {userData.user.email}
            )
          </div>
        </div>
        <Button type="button" color="info" className="btn p-0" onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={icon({ name: 'arrow-left', family: 'classic', style: 'regular' })}
            size="xl"
          />
        </Button>
        <form className=" flex-col min-w-[300px]" onSubmit={onUserRoleSubmit}>
          <input type="hidden" {...userRoleRegister('userId', { value: userData?.user.id })} />
          <input type="hidden" {...userRoleRegister('roles', { value: userData?.user.userRoles })} />
          <div className="font-bold my-4 text-gray-500 dark:text-gray-400 text-center">
            User Roles:
          </div>
          <Select
            defaultValue={userData?.user.userRoles.map((role) => ({
              label: role.toString(),
              value: role,
            }
            ))}
            isMulti
            options={Object.values(RoleUser).map((role) => ({
              label: role.toString(),
              value: role,
            }))}
            className="w-full"
            classNamePrefix="select"
            onChange={(e) => setRolesForUserRoles(e)}
          />

          {setUserRolesError && (
          <Alert
            color="failure"
          >
            <span>
              <span className="font-medium">
                Setting user roles failed!
              </span>
              {' '}
              {setUserRolesError?.message}
            </span>
          </Alert>
          )}

          <div className="flex justify-center mt-4 md:mt-4">
            {setUserRolesLoading || setUserRolesError ? (
              <Button type="submit" color="info" className="btn p-0" disabled>
                Save user roles
              </Button>
            ) : (
              <Button type="submit" color="info" className="btn p-0">
                Save user roles
              </Button>
            )}
          </div>
        </form>

        <Table className="mt-4" hoverable>
          <Table.Head>
            <Table.HeadCell>
              Org id
            </Table.HeadCell>
            <Table.HeadCell>
              Org name
            </Table.HeadCell>
            <Table.HeadCell>
              Roles
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex items-center gap-x-2">
                Manage
                <Button type="button" color="info" className="btn p-0 justify-self-end" onClick={() => setSelectedOrganization({ orgId: undefined, organizationRoles: [] })}>
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="lg"
                    icon={icon({ name: 'plus' })}
                  />
                </Button>
              </div>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {userData?.user.organizations.map((org) => (
              <Table.Row
                key={org.organization.orgId}
                className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
              >
                <Table.Cell>
                  {org.organization.name}
                </Table.Cell>
                <Table.Cell>
                  {org.organization.orgId}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex flex-wrap gap-2 items-center max-w-[460px]">
                    {org.organizationRoles.map((r) => (
                      <Badge key={`orgrole-${org.organization.orgId}_${r}`} color="info">
                        <div className="capitalize inline text-xs">
                          {r}
                        </div>
                      </Badge>
                    ))}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    size="xl"
                    icon={icon({ name: 'pencil' })}
                    onClick={() => setSelectedOrganization({ orgId: org.organization.orgId, organizationRoles: org.organizationRoles })}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default UserRolesEdit;
