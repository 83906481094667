import React, { Fragment } from 'react';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { Avatar, Badge, Dropdown } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { DashboardRoute } from './Routes';
import useMyRoles from './hooks/useMyRoles';

function Profile(): JSX.Element {
  const { idTokenPayload } = useOidcIdToken();
  const { logout, isAuthenticated } = useOidc();
  const myRoles = useMyRoles();

  if (!isAuthenticated) {
    return <> </>;
  }

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={<Avatar rounded />}
    >
      <Dropdown.Header>
        <span className="block text-sm">
          {idTokenPayload?.firstname ?? 'onbekend'}
        </span>
        <span className="block truncate text-sm font-medium">
          {idTokenPayload?.email ?? 'onbekend'}
        </span>
      </Dropdown.Header>
      {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
        <>
          <Dropdown.Header>
            <div className="flex flex-wrap gap-1 max-w-[300px]">
              {myRoles?.userRoles.map((role) => (
                <Badge key={`userrole-${role}`} color="success">
                  <div className="capitalize inline text-xs">{role}</div>
                </Badge>
              ))}
            </div>
          </Dropdown.Header>
          <Dropdown.Header>
            <div className="flex flex-wrap space-x-1 space-y-2 max-w-[300px]">
              {myRoles?.organizationRoles.map((orgWithRoles) => (
                <Fragment key={`orgrole-${orgWithRoles.orgId}`}>
                  {orgWithRoles.roles.map((r) => (
                    <Badge key={`orgrole-${orgWithRoles.orgId}_${r}`} color="info">
                      <div className="capitalize inline text-xs">
                        {orgWithRoles.orgId}
                        _
                        {r}
                      </div>
                    </Badge>
                  ))}
                </Fragment>
              ))}
            </div>
          </Dropdown.Header>
        </>
      )}
      <Link to={DashboardRoute()}>
        <Dropdown.Item>
          Dashboard
        </Dropdown.Item>
      </Link>
      <Dropdown.Divider />
      <button type="button" className="w-full" onClick={() => logout('/')}>
        <Dropdown.Item>
          Uitloggen
        </Dropdown.Item>
      </button>
    </Dropdown>
  );
}

export default Profile;
