import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Button, Alert,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  format, parseISO,
} from 'date-fns';
import * as Sentry from '@sentry/react';
import Footer from '../../Footer';
import { SRRoute } from '../../Routes';
import {
  CreateAssetCommand, useCreateRegisteredSensorAssetMutation,
} from '../../../generated/gql/types';
import { RegisteredSensorAssetProperty } from '../../../AssetHelpers';
import AssetForm from './AssetForm';
import { ReactComponent as InfoSvg } from '../../../images/info.svg';
import { WeCityOrganizationIdHeader } from '../../../Global';

function AssetCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const [createAsset, {
    loading,
    error,
  }] = useCreateRegisteredSensorAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateAssetCommand>({});

  const onSubmit = handleSubmit((createCommand) => {
    createAsset({
      variables: {
        command: {
          name: createCommand.name,
          properties: createCommand.properties?.map((property) => {
            if (property.key === RegisteredSensorAssetProperty.ValidUntil) {
              return { ...property, ...{ value: format(parseISO(property.value!), 'y-MM-dd') } };
            }

            return property;
          }).filter((p) => p.value !== undefined && p.value !== '' && p.key !== undefined && p.type !== undefined),
        },
      },
    }).catch((e) => {
      Sentry.captureMessage(e.toString(), 'error');
    }).then((_) => {
      navigate(SRRoute(params.orgId), { state: { toastCreated: true } });
    });
  });

  return (
    <div>
      <Card>
        {error && (
          <Alert
            color="failure"
            icon={InfoSvg}
          >
            <span>
              <span className="font-bold pr-2">
                Let op:
              </span>
              Oops er ging iets mis bij het opslaan, controleer de velden.
              {error.message}
            </span>
          </Alert>
        )}
        <form className="flex-col grid grid-cols-1 gap-6 sm:grid-cols-2" onSubmit={onSubmit}>

          <AssetForm asset={undefined} register={register} errors={errors} setValue={setValue} />

          {loading ? (
            <Button type="submit" className="btn opacity-50 disabled:pointer-events-none inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium" disabled>
              {t('save changes')}
            </Button>
          ) : (
            <Button type="submit" className="btn inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium focus:outline-none focus:ring-4 hover:bg-wc-hover-blue">
              {t('save changes')}
            </Button>
          )}
        </form>
      </Card>
      <Footer />
    </div>
  );
}

export default AssetCreate;
