import React from 'react';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  Card,
  Button, Alert,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  format, parseISO,
} from 'date-fns';
import Footer from '../../Footer';
import { SRRoute } from '../../Routes';
import {
  RoleOrganization,
  UpdateAssetCommand, useDeleteRegisteredSensorPropertyMutation,
  useGetRegisteredSensorAssetQuery,
  useUpdateRegisteredSensorAssetMutation,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import { RegisteredSensorAssetProperty } from '../../../AssetHelpers';
import AssetForm from './AssetForm';
import useMyRoles from '../../hooks/useMyRoles';
import { WeCityOrganizationIdHeader } from '../../../Global';

function AssetEdit() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const myRoles = useMyRoles();
  const orgIdRoles = (myRoles?.organizationRoles ?? []).find((or) => or.orgId === params.orgId)?.roles ?? [];

  const [updateAsset, {
    loading: loadingUpdate,
    error: updateError,
  }] = useUpdateRegisteredSensorAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const [deleteProperty, {
    loading: loadingPropertyDelete,
    error: deletePropertyError,
  }] = useDeleteRegisteredSensorPropertyMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const { data, loading, error } = useGetRegisteredSensorAssetQuery({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
    variables: {
      id: params.assetId ?? '',
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAssetCommand>({});

  const onSubmit = handleSubmit((updateCommand) => {
    // delete properties with empty values
    const deletedProperties = updateCommand.properties?.filter((p) => (p.value === undefined || p.value === '') && p.id !== undefined)
      .map((property) => deleteProperty({
        variables: {
          command: {
            assetId: updateCommand?.id!,
            propertyId: property.id!,
          },
        },
      })) ?? [];

    const updatedAsset = updateAsset({
      variables: {
        command: {
          id: updateCommand.id,
          name: updateCommand.name,
          properties: updateCommand.properties?.filter((p) => p.value !== undefined && p.value !== '' && p.key !== undefined && p.type !== undefined)
            .map((property) => {
              if (property.key === RegisteredSensorAssetProperty.ValidUntil) {
                return { ...property, ...{ value: format(parseISO(property.value!), 'y-MM-dd') } };
              }

              return property;
            }),
        },
      },
    });

    Promise.all([...deletedProperties, updatedAsset]).then((_) => {
      navigate(SRRoute(params.orgId), { state: { toastChanged: true } });
    });
  });

  const assetData = data?.registeredSensorAsset?.data;

  if (loading || !assetData) {
    return <Loader />;
  }

  if (error) {
    return <div>Oops something went wrong</div>;
  }

  return (
    <div>
      <Card>

        {updateError && (
          <Alert
            color="failure"
          >
            <div>
              <span className="font-bold pr-2">
                Let op:
              </span>
              <span>{updateError.message}</span>
            </div>
          </Alert>
        )}
        {deletePropertyError && (
          <Alert
            color="failure"
          >
            <div>
              <span className="font-bold pr-2">
                Let op:
              </span>
              <span>{deletePropertyError.message}</span>
            </div>
          </Alert>
        )}
        <form className="flex-col grid grid-cols-1 gap-6 sm:grid-cols-2 min-h-full" onSubmit={onSubmit}>
          <input type="hidden" {...register('id', { value: assetData.id })} />

          <AssetForm asset={assetData} register={register} errors={errors} setValue={setValue} />

          {(loadingUpdate || loadingPropertyDelete || !orgIdRoles.find((role) => role === RoleOrganization.OrgSensorRegisterManager)) ? (
            <Button
              type="submit"
              className="btn opacity-50 disabled:pointer-events-none inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium"
              disabled
            >
              {t('save changes')}
            </Button>
          ) : (
            <Button
              type="submit"
              className="btn inline-flex items-center justify-self-center self-start rounded-lg p-2 w-80 text-center font-medium focus:outline-none focus:ring-4 hover:bg-wc-hover-blue"
            >
              {t('save changes')}
            </Button>
          )}
        </form>
      </Card>
      <Footer />
    </div>
  );
}

export default AssetEdit;
