import React from 'react';
import { Button, Tooltip } from 'flowbite-react';

function CopyToClipboardButton({
  content, clipboardData, tooltipMessage, color = 'none',
}: CopyToClipboardButtonProps) {
  return (
    <Tooltip
      content={tooltipMessage}
      trigger="click"
    >
      <Button
        className="btn"
        color={color}
        type="button"
        onClick={() => navigator.clipboard.writeText(clipboardData)}
      >
        {content}
      </Button>
    </Tooltip>
  );
}

type CopyToClipboardButtonProps = {
  clipboardData: string
  content: JSX.Element | string
  tooltipMessage: string
  // eslint-disable-next-line react/require-default-props
  color?: string;
};

export default CopyToClipboardButton;
