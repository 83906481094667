import React from 'react';
import _ from 'lodash';
import useMyRoles from '../../hooks/useMyRoles';
import Loader from '../../Loader';
import { RoleUser } from '../../../generated/gql/types';

function ApiCatalogApp() {
  const myRoles = useMyRoles();

  if (!myRoles || process.env.REACT_APP_API_SUBSCRIPTIONS_ENABLED === 'false') {
    return <Loader />;
  }

  return (
    <div className="mx-2.5 md:mx-0">
      <section>
        {_.difference([RoleUser.UserApiCatalogUser], myRoles.userRoles).length === 0 && (
        // SHOW API
          <div>Coming soon</div>
        )}
      </section>
    </div>
  );
}

export default ApiCatalogApp;
