import React from 'react';
import { Navbar } from 'flowbite-react';
import Loader from './Loader';
import logo from '../images/wecity_logo_main.png';
import { DashboardRoute } from './Routes';

function LoadingPage() {
  return (
    <div className="container mx-auto">
      <Navbar
        fluid
        rounded
        className="mt-5 pb-5 mb-5 border-b border-slate-500"
      >
        <Navbar.Brand href={DashboardRoute()}>
          <img src={logo} alt="Logo" className="mr-3 h-6 sm:h-9" />
        </Navbar.Brand>

      </Navbar>

      <div role="status">
        <Loader />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default LoadingPage;
