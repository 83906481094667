import { useEffect, useState } from 'react';
import {
  RoleOrganization, RoleUser, useGetMyOrganizationRolesQuery, useGetMyUserRolesQuery,
} from '../../generated/gql/types';

type OrganizationWithRoles = {
  orgId: string
  roles: RoleOrganization[]
};

export type MyRoles = {
  userRoles: RoleUser[]
  organizations: string[]
  organizationRoles: OrganizationWithRoles[]
};

function useMyRoles(): MyRoles | undefined {
  const [myRoles, setMyRoles] = useState<MyRoles | undefined>(undefined);
  const { data: userRoles, loading: userRolesLoading } = useGetMyUserRolesQuery({
    fetchPolicy: 'network-only',
  });
  const { data: organizationRoles, loading: organizationRolesLoading } = useGetMyOrganizationRolesQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (organizationRoles && userRoles) {
      const organizationRolesMapping = organizationRoles.myOrganizationRoles.map((item) => (
        {
          orgId: item.organization.orgId,
          roles: item.organizationRoles,
        } as OrganizationWithRoles
      ));

      setMyRoles({
        userRoles: userRoles.myUserRoles,
        organizations: organizationRoles.myOrganizationRoles.map((item) => item.organization.orgId),
        organizationRoles: organizationRolesMapping,
      });
    }
  }, [userRoles, organizationRoles, userRolesLoading, organizationRolesLoading]);

  return myRoles;
}

export default useMyRoles;
