import React, { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function Dropdown({
  register, options, hasError = false,
}: DropdownProps) {
  const { t } = useTranslation();

  function renderOptions() {
    return (
      <>
        <option value="undefined" disabled>
          {t('choose')}
        </option>

        {options.map((o) => (
          <option key={`option${o.value}`} value={o.value}>{o.name}</option>
        ))}
      </>
    );
  }

  if (hasError) {
    return (
      <select
        className="block w-full p-2 mb-4 text-sm text-gray-900 border border-red-500 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
        {...register}
      >
        {renderOptions()}
      </select>
    );
  }

  return (
    <select
      className="block w-full p-2 mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      {...register}
    >
      {renderOptions()}
    </select>
  );
}

export type DropdownOption = {
  name: ReactNode
  value: string
};

type DropdownProps = {
  register: UseFormRegisterReturn<any>
  options: DropdownOption[]
  // eslint-disable-next-line react/require-default-props
  hasError?: boolean,
};

export default Dropdown;
