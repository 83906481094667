import { Outlet } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Navigation from './Navigation';

function Layout() {
  return (
    <div className="container mx-auto">
      <ToastContainer />
      <Navigation hasProfile />

      <Outlet />
    </div>
  );
}

export default Layout;
