import { useOidc } from '@axa-fr/react-oidc';
import React from 'react';
import { Link } from 'react-router-dom';
import { DashboardRoute, LandingRoute } from './Routes';
import dashboard1 from '../images/dashboard1.jpg';
import dashboard2 from '../images/dashboard2.jpg';
import dashboard3 from '../images/dashboard3.jpg';

function Landing() {
  const { logout, isAuthenticated } = useOidc();

  return (
    <div>
      {!isAuthenticated
    && (
    <section className="bg-white">
      <div className="grid justify-center py-8 px-4">
        <div className="grid gap-4 grid-cols-3 dbimgcont mb-6">
          <img className="w-full rounded-lg dbimg" src={dashboard1} alt="illustratie straat" />
          <img className="w-full rounded-lg dbimg" src={dashboard2} alt="illustratie bouw" />
          <img className="w-full rounded-lg dbimg" src={dashboard3} alt="illustratie tuin" />
        </div>
        <h2 className="mb-4 text-center  text-4xl tracking-tight font-extrabold text-gray-900">Welkom</h2>
        <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">Via deze pagina kun je inloggen op jouw persoonlijke omgeving. </p>
        <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">
          <Link
            to={DashboardRoute()}
            className="btn-card border group h-min text-center font-bold rounded-lg text-sm focus:z-10
            inline-grid items-center justify-center p-0.5 px-2 m-5"
          >
            <span className="text-center rounded-md text-sm px-4 py-2">Inloggen</span>
          </Link>
        </p>
      </div>
    </section>
    )}
      {isAuthenticated
      && (
      <section className="bg-white">
        <div className="grid justify-center py-8 px-4">
          <div className="grid gap-4 grid-cols-3 dbimgcont mb-6">
            <img className="w-full rounded-lg dbimg" src={dashboard1} alt="illustratie straat" />
            <img className="w-full rounded-lg dbimg" src={dashboard2} alt="illustratie bouw" />
            <img className="w-full rounded-lg dbimg" src={dashboard3} alt="illustratie tuin" />
          </div>
          <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">Hier kun je uitloggen.</p>
          <p className="mb-4 text-center font-light text-gray-500 sm:text-lg">
            <button
              type="button"
              onClick={() => logout(LandingRoute())}
              className="border bg-wc-lightgray border-wc-gray hover:text-white hover:bg-wc-gray group h-min flex items-center justify-center p-0.5 px-2 m-5 text-center font-medium focus:z-10 rounded-lg inline-grid"
            >
              <span className="text-center rounded-md text-sm px-4 py-2">Uitloggen</span>
            </button>
          </p>
        </div>
      </section>
      )}
    </div>
  );
}

export default Landing;
