import React from 'react';
import { ReactComponent as ArrowRightSvg } from '../../images/arrow_right.svg';

function ArrowRight() {
  return (
    <div className="ml-2 -mr-1 h-4 w-4">
      <ArrowRightSvg />
    </div>
  );
}

export default ArrowRight;
