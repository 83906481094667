import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMyRoles from '../../hooks/useMyRoles';
import { RoleOrganization } from '../../../generated/gql/types';
import { SRRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';
import Footer from '../../Footer';

function MultiRegisteredSensorApp() {
  const myRoles = useMyRoles();
  const { t } = useTranslation();
  const pinIconStyle = {
    '--fa-primary-color': '#12678F',
    '--fa-secondary-color': '#A6C5D4',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;

  return (
    <div className="mx-2.5 md:mx-0">
      <section className="flex flex-row flex-wrap gap-4 ">
        {myRoles?.organizationRoles.filter((o) => o.roles.find((r) => r === RoleOrganization.OrgSensorRegisterManager || RoleOrganization.OrgSensorRegisterUser)).map((orgWithRoles) => (
          <div key={orgWithRoles.orgId} className="wccard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
            <div className="flex h-full flex-col justify-top">
              <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
                <h4 className="flex-grow inline-left text-2xl font-bold text-gray-900">
                  {t('manage sensor registry')}
                  {' '}
                  <div className="capitalize-first">{orgWithRoles.orgId}</div>
                </h4>
                <FontAwesomeIcon
                  className="h-[60px] self-center inline-right"
                  icon={icon({ name: 'map-location-dot', family: 'classic', style: 'duotone' })}
                  style={pinIconStyle}
                />
              </div>

              <Link
                to={SRRoute(orgWithRoles.orgId)}
                className="btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
              >
                <span className="flex items-center rounded-md text-sm px-4 py-2">
                  {orgWithRoles.roles.find((r) => r === RoleOrganization.OrgSensorRegisterManager)
                    ? (t('register sensors')) : (t('view sensors'))}
                  <ArrowRight />
                </span>
              </Link>

            </div>
          </div>
        ))}
      </section>
      <Footer />
    </div>
  );
}

export default MultiRegisteredSensorApp;
