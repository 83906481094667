import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ArrowRight from './elements/ArrowRight';

function ErrorCard({ moduleName }: ErrorCardProps) {
  const { t } = useTranslation();

  function refreshPage() {
    window.location.reload();
  }
  const alertTriangleStyle = {
    '--fa-primary-color': '#ff8c00',
    '--fa-secondary-color': '#ffbe6f',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  } as CSSProperties;

  return (
    <div className="wccard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-red-50 rounded-t-lg p-5 border-b-4 border-red-500 min-h-[116px]">
          <h4 className="flex-grow inline-left text-2xl font-bold text-gray-900">
            Error
            {' '}
            {moduleName}
          </h4>
          <FontAwesomeIcon
            icon={icon({ name: 'triangle-exclamation', family: 'classic', style: 'duotone' })}
            size="4x"
            style={alertTriangleStyle}
          />
        </div>
        <div className="h-full p-5 mb-5">
          <div className="text-gray-900">
            {t('something went wrong please try again later')}
          </div>
        </div>
        <button
          type="button"
          onClick={() => refreshPage()}
          className="btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Refresh
            <ArrowRight />
          </span>
        </button>

      </div>
    </div>
  );
}

type ErrorCardProps = {
  moduleName: string
};

export default ErrorCard;
