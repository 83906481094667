import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SRRouteDashboard } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';
import useMyRoles from '../../hooks/useMyRoles';
import { RoleOrganization } from '../../../generated/gql/types';

function MultiRegisteredSensorCard() {
  const myRoles = useMyRoles();
  const pinIconStyle = {
    '--fa-primary-color': '#12678F',
    '--fa-secondary-color': '#A6C5D4',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;

  const { t } = useTranslation();

  return (
    <div className="wccard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <h4 className="flex-grow inline-left mt-2 text-2xl font-bold text-gray-900">
            {t('manage your sensorregister organizations')}
          </h4>
          <FontAwesomeIcon
            className="h-[60px] self-center inline-right"
            icon={icon({ name: 'map-location-dot', family: 'classic', style: 'duotone' })}
            style={pinIconStyle}
          />
        </div>

        <div className="h-full p-5 mb-5">
          <div className="rounded-lg p-5 mb-2 border border-gray-300">
            <div className="text-gray-900">
              <h5 className="text-2xl font-bold text-left text-gray-900">
                {t('organizations')}
              </h5>
              <div className="text-[5.5rem] leading-[6rem] text-center font-extrabold">
                {myRoles?.organizationRoles.filter((o) => o.roles.find((r) => r === RoleOrganization.OrgSensorRegisterManager || RoleOrganization.OrgSensorRegisterUser)).length}
              </div>
            </div>
          </div>
        </div>

        <Link
          to={SRRouteDashboard()}
          className="btn-card group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            {t('register sensors')}
            <ArrowRight />
          </span>
        </Link>

      </div>
    </div>
  );
}

export default MultiRegisteredSensorCard;
