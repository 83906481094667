import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import {
  Asset,
  useGetRegisteredSensorSchemeQuery,
  useUpdateRegisteredSensorPropertyMutation,
} from '../../../generated/gql/types';
import { getProperty, RegisteredSensorAssetProperty, validationStringToOptionList } from '../../../AssetHelpers';
import { WeCityOrganizationIdHeader } from '../../../Global';

function UpdateStatusDropdown({ selectedAssets, updateCompleteHandler }: UpdateStatusDropdownProps) {
  const { t } = useTranslation();
  const params = useParams();
  const {
    data,
    loading,
  } = useGetRegisteredSensorSchemeQuery({ context: { clientName: 'asset' } });

  const [updateAssetProperty, { loading: updateLoading }] = useUpdateRegisteredSensorPropertyMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function updateProperty(asset: Asset, value: string) {
    const property = getProperty(asset, RegisteredSensorAssetProperty.Status);
    if (property) {
      return updateAssetProperty({
        variables: {
          command: {
            assetId: asset.id,
            value,
            propertyId: property.id,
          },
        },
      });
    }
    // @TODO Should we create the property when it does not exist?
    return Promise.resolve();
  }

  function updateAssets(value: string) {
    Promise.all(selectedAssets.map((asset) => (updateProperty(asset, value)))).then((_) => {
      updateCompleteHandler();
    });
  }

  function renderOptions() {
    return (
      <>
        <option value="undefined" disabled>
          {t('change status')}
        </option>

        {validationStringToOptionList(
          data?.scheme?.data?.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.Status)?.validation ?? '',
          t,
        ).map((o) => (
          <option key={`option${o.value}`} value={o.value} className="capitalize-first">{o.name}</option>
        ))}
      </>
    );
  }

  if (loading || updateLoading) {
    return (
      <Select
        value="undefined"
        disabled
      />
    );
  }

  if (selectedAssets.length > 0) {
    return (
      <Select
        value="undefined"
        onChange={(e) => updateAssets(e.target.value)}
      >
        {renderOptions()}
      </Select>
    );
  }

  return (
    <Select
      value="undefined"
      disabled
    >
      {renderOptions()}
    </Select>
  );
}

type UpdateStatusDropdownProps = {
  selectedAssets: Asset[]
  updateCompleteHandler: () => void
};

export default UpdateStatusDropdown;
