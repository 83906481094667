import React from 'react';
import {
  TextInput, Checkbox, Label, Textarea,
} from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FieldErrors, UseFormRegister, UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  format, isValid, parse, parseISO,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import { LatLng } from 'leaflet';
import {
  Asset, CreateAssetCommand,
  Property,
  UpdateAssetCommand,
  useGetRegisteredSensorSchemeQuery,
} from '../../../generated/gql/types';
import Loader from '../../Loader';
import Dropdown from '../pientere-tuinen/Dropdown';
import { getProperty, RegisteredSensorAssetProperty, validationStringToOptionList } from '../../../AssetHelpers';
import MapCoordinatesPicker from '../pientere-tuinen/MapCoordinatesPicker';
import { WeCityOrganizationIdHeader } from '../../../Global';

function AssetForm({
  asset, register, errors, setValue,
}: AssetFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: scheme,
    loading: schemaLoading,
    error,
  } = useGetRegisteredSensorSchemeQuery({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function latLongFromProperty(property: Property | undefined): LatLng | undefined {
    if (property !== undefined && property.value) {
      const ll = property.value.split(',');

      if (ll[0] && !Number.isNaN(Number(ll[0])) && ll[1] && !Number.isNaN(Number(ll[1]))) {
        return new LatLng(Number(ll[0]), Number(ll[1]));
      }
    }

    return undefined;
  }

  const schemeData = scheme?.scheme?.data;

  if (schemaLoading || !schemeData) {
    return <Loader />;
  }

  if (error) {
    return <div>Oops something went wrong</div>;
  }

  const assetCoordinates = asset ? latLongFromProperty(
    getProperty(asset, RegisteredSensorAssetProperty.Coordinates),
  ) : undefined;
  const defaultCoordinates = new LatLng(Number('52.084931'), Number('5.241257'));

  function mapDateForDatePicker(date: string) {
    const validUntil = parse(date, 'y-MM-dd', new Date());

    if (isValid(validUntil)) {
      return date;
    }

    return undefined;
  }

  function renderPropertyInputMeta(index: number, type: RegisteredSensorAssetProperty) {
    const property = asset ? getProperty(asset, type) : undefined;

    if (property) {
      return (
        <>
          <input type="hidden" {...register(`properties.${index}.id`, { value: property.id })} />
          <input type="hidden" {...register(`properties.${index}.type`, { value: type })} />
          <input type="hidden" {...register(`properties.${index}.key`, { value: type })} />
        </>
      );
    }

    return (
      <>
        <input type="hidden" {...register(`properties.${index}.type`, { value: type })} />
        <input type="hidden" {...register(`properties.${index}.key`, { value: type })} />
      </>
    );
  }

  return (
    <>
      <h4
        className="mt-2 text-2xl font-bold text-gray-900 flex-grow"
      >
        {asset ? t('edit sensor') : t('create sensor')}
      </h4>
      <div className="grid justify-items-end">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="btn-card px-4 py-3 m-2 w-[42px] justify-self-end focus:z-10 rounded-lg"
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="flex flex-col row-span-2">
        <div className="mb-2 block">
          <Label
            htmlFor="name"
            value={`${t('sensor name')}`}
          />
          <TextInput
            id="name"
            placeholder=""
            required
            type="text"
            {...register('name', { value: asset?.name ?? '' })}
          />
        </div>
        <div className="mb-2 block">
          <Label
            htmlFor="description"
            value={`${t('description')}`}
          />
          {renderPropertyInputMeta(0, RegisteredSensorAssetProperty.Goal)}
          <Textarea
            className="text-[14px]"
            id="goal"
            required
            rows={3}
            {...register('properties.0.value', { value: asset ? getProperty(asset, RegisteredSensorAssetProperty.Goal)?.value ?? '' : '' })}
          />
        </div>
        <div className="flex-col grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="mb-2 block">
            <Label
              htmlFor="type"
              value={`${t('type')}`}
            />
            {renderPropertyInputMeta(1, RegisteredSensorAssetProperty.SensorType)}
            <Dropdown
              hasError={errors?.properties?.[1] !== undefined}
              register={register('properties.1.value', {
                required: true,
                pattern: /^((?!undefined).)*$/,
                value: asset ? getProperty(asset, RegisteredSensorAssetProperty.SensorType)?.value ?? 'undefined' : 'undefined',
              })}
              options={
                validationStringToOptionList(
                  schemeData.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.SensorType)?.validation ?? '',
                  t,
                )
              }
            />
          </div>
          <div className="mb-2 block">
            <Label
              htmlFor="theme"
              value={`${t('theme')}`}
            />
            {renderPropertyInputMeta(2, RegisteredSensorAssetProperty.Theme)}
            <Dropdown
              hasError={errors?.properties?.[1] !== undefined}
              register={register('properties.2.value', {
                required: true,
                pattern: /^((?!undefined).)*$/,
                value: asset ? getProperty(asset, RegisteredSensorAssetProperty.Theme)?.value ?? 'undefined' : 'undefined',
              })}
              options={
                validationStringToOptionList(
                  schemeData?.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.Theme)?.validation ?? '',
                  t,
                )
              }
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          {renderPropertyInputMeta(3, RegisteredSensorAssetProperty.ProcessesPersonalData)}
          <Checkbox
            defaultChecked={asset ? getProperty(asset, RegisteredSensorAssetProperty.ProcessesPersonalData)?.value !== '0' : false}
            id="personaldata"
            {...register('properties.3.value')}
          />

          <Label htmlFor="personaldata">
            {t('process personal data')}
          </Label>
        </div>
        <div className="flex items-center gap-2">
          {renderPropertyInputMeta(4, RegisteredSensorAssetProperty.IsMobile)}
          <Checkbox
            className="mb-2"
            defaultChecked={asset ? getProperty(asset, RegisteredSensorAssetProperty.IsMobile)?.value !== '0' : false}
            id="movable"
            {...register('properties.4.value')}
          />
          <Label htmlFor="movable">
            {t('moveable sensor')}
          </Label>
        </div>
        <div className="block">
          {renderPropertyInputMeta(11, RegisteredSensorAssetProperty.Coordinates)}
          <MapCoordinatesPicker
            register={register('properties.11.value', {
              required: true,
              pattern: /[^0]+/,
              value: (assetCoordinates ? `${assetCoordinates.lat}, ${assetCoordinates.lng}` : `${defaultCoordinates.lat}, ${defaultCoordinates.lng}`),
            })}
            setValue={setValue}
            location={assetCoordinates ?? defaultCoordinates}
            zoomLevel={assetCoordinates ? 18 : 9}
            withInputFields
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2 block">
          <Label
            htmlFor="status"
            value={`${t('status')}`}
          />
          {renderPropertyInputMeta(5, RegisteredSensorAssetProperty.Status)}
          <Dropdown
            hasError={errors?.properties?.[5] !== undefined}
            register={register('properties.5.value', {
              required: true,
              pattern: /^((?!undefined).)*$/,
              value: asset ? getProperty(asset, RegisteredSensorAssetProperty.Status)?.value ?? 'undefined' : 'undefined',
            })}
            options={
              validationStringToOptionList(
                schemeData.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.Status)?.validation ?? '',
                t,
              )
            }
          />
          <div className="mb-2 block">
            <Label
              htmlFor="validity"
              value={`${t('valid until')}`}
            />
            {renderPropertyInputMeta(6, RegisteredSensorAssetProperty.ValidUntil)}
            <TextInput
              id="validity"
              type="date"
              defaultValue={mapDateForDatePicker(asset ? getProperty(asset, RegisteredSensorAssetProperty.ValidUntil)?.value ?? new Date().toString() : new Date().toString())}
              {
              ...register('properties.6.value', { required: true })
              }
            />
          </div>
          <div className="mb-2 block">
            {renderPropertyInputMeta(7, RegisteredSensorAssetProperty.LegislationUrl)}
            <Label
              htmlFor="legal"
              value={`${t('legal basis')}`}
            />
            <TextInput
              id="legal"
              type="text"
              {...register('properties.7.value', {
                value: asset ? getProperty(asset, RegisteredSensorAssetProperty.LegislationUrl)?.value ?? '' : '',
              })}
            />
          </div>
          <div className="mb-2 block">
            {renderPropertyInputMeta(8, RegisteredSensorAssetProperty.ContactName)}
            <Label
              htmlFor="contactperson"
              value={`${t('contact person')}`}
            />
            <TextInput
              id="contactperson"
              type="text"
              {...register('properties.8.value', { value: asset ? getProperty(asset, RegisteredSensorAssetProperty.ContactName)?.value ?? '' : '' })}
            />
          </div>
          <div className="mb-2 block">
            {renderPropertyInputMeta(9, RegisteredSensorAssetProperty.ContactEmail)}
            <Label
              htmlFor="email"
              value={`${t('email')}`}
            />
            <TextInput
              id="email"
              type="email"
              {...register('properties.9.value', { value: asset ? getProperty(asset, RegisteredSensorAssetProperty.ContactEmail)?.value ?? '' : '' })}
            />
          </div>
          <div className="mb-2 block">
            {renderPropertyInputMeta(10, RegisteredSensorAssetProperty.Organization)}
            <Label
              htmlFor="organization"
              value={`${t('organization')}`}
            />
            <TextInput
              id="organisation"
              type="text"
              {...register('properties.10.value', { value: asset ? getProperty(asset, RegisteredSensorAssetProperty.Organization)?.value ?? '' : '' })}
            />
          </div>
          <div className="mb-2 block">
            <Label
              htmlFor="reference"
              value={`${t('reference')}`}
              className="capitalize"
            />
            {renderPropertyInputMeta(12, RegisteredSensorAssetProperty.SensorReferenceId)}
            <TextInput
              id="reference"
              type="text"
              {...register('properties.12.value', { value: asset ? getProperty(asset, RegisteredSensorAssetProperty.SensorReferenceId)?.value ?? '' : '' })}
            />
            {asset && (
              <>
                <p className="mt-6 mb-2 text-[14px] text-gray-900">
                  {t('registered')}
                  {': '}
                  {format(parseISO(asset.createdAt), 'dd-MM-y', { locale: nl })}
                </p>
                <p className="mt-2 mb-2 text-[14px] text-gray-900">
                  {t('modified')}
                  {': '}
                  {format(parseISO(asset.updatedAt), 'dd-MM-y', { locale: nl })}
                </p>
              </>
            )}

          </div>
        </div>
      </div>
    </>
  );
}

type AssetFormProps = {
  asset: Asset | undefined
  register: UseFormRegister<UpdateAssetCommand | CreateAssetCommand>
  errors: FieldErrors<UpdateAssetCommand | CreateAssetCommand>
  setValue: UseFormSetValue<UpdateAssetCommand | CreateAssetCommand>
};

export default AssetForm;
