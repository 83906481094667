import React, {
  useEffect,
  useState,
} from 'react';
import {
  MapContainer, Marker, Popup, TileLayer, useMapEvent, ZoomControl,
} from 'react-leaflet';
import { LatLng, LatLngBounds, LeafletMouseEvent } from 'leaflet';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { Label, TextInput } from 'flowbite-react';

function SetCoordinatesOnClick({ clickHandler }: SetCoordinatesOnClickProps) {
  useMapEvent('click', (e: LeafletMouseEvent) => {
    clickHandler(e.latlng);
  });

  return null;
}

export function latLngToString(latLng: LatLng) {
  return `${latLng.lat}, ${latLng.lng}`;
}

function MapCoordinatesPicker({
  register, setValue, location, zoomLevel, withInputFields,
}: MapCoordinatesPickerProps) {
  const [
    coordinates,
    setCoordinates,
  ] = useState<LatLng>(location);

  useEffect(() => {
    setValue(register.name, latLngToString(coordinates));
  }, [coordinates]);

  function updateCoordinatesFromInput(lat: string | undefined, lng: string | undefined) {
    if (lat && parseFloat(lat)) {
      setCoordinates(new LatLng(parseFloat(lat), coordinates.lng));
    } else if (lng && parseFloat(lng)) {
      setCoordinates(new LatLng(coordinates.lat, parseFloat(lng)));
    }
  }

  function renderMap() {
    return (
      <div className="py-2 flex flex-col gap-1">
        {withInputFields && (
          <div className="flex-col grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="mb-2 block">
              <Label
                htmlFor="latitude"
                value="Latitude"
              />
              <TextInput
                id="latitude"
                value={coordinates.lat}
                onChange={(e) => updateCoordinatesFromInput(e.target.value, undefined)}
              />
            </div>
            <div className="mb-2 block">
              <Label
                htmlFor="longitude"
                value="Longitude"
              />
              <TextInput
                id="longitude"
                value={coordinates.lng}
                onChange={(e) => updateCoordinatesFromInput(undefined, e.target.value)}
              />
            </div>
          </div>
        )}

        <input
          type="hidden"
          {...register}
        />
        <div className="block mb-2">
          <MapContainer
            style={{ height: '300px' }}
            center={coordinates}
            zoom={zoomLevel}
            minZoom={8}
            scrollWheelZoom
            zoomControl={false}
            maxBounds={new LatLngBounds(
              new LatLng(50.6309883, 3.0625035),
              new LatLng(53.7181849, 7.2928109),
            )}
          >
            <ZoomControl position="topleft" />

            <TileLayer
              attribution=""
              url={`${process.env.REACT_APP_WMTS_URL}/topoplus_achtergrond/wm_19/{z}/{x}/{y}.jpeg`}
            />

            <Marker
              position={coordinates}
            >
              <Popup minWidth={90}>
                <span>
                  Klik op de kaart om de locatie van de sensor aan te geven
                </span>
              </Popup>
            </Marker>

            <SetCoordinatesOnClick clickHandler={setCoordinates} />
          </MapContainer>
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderMap()}
    </div>
  );
}

type MapCoordinatesPickerProps = {
  register: UseFormRegisterReturn<any>
  setValue: UseFormSetValue<any>
  location: LatLng
  zoomLevel: number
  withInputFields: boolean
};

type SetCoordinatesOnClickProps = {
  clickHandler: (p: LatLng) => void
};

export default MapCoordinatesPicker;
