import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button, Table, TextInput, Toast,
} from 'flowbite-react';
import {
  format, isValid, parse, parseISO,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faEdit, faMagnifyingGlass, faXmark, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import Footer from '../../Footer';
import { SRAssetCreateRoute, SRAssetImportRoute, SRAssetRoute } from '../../Routes';
import Pagination from './Pagination';
import AssetCheckbox, { Option } from './AssetCheckbox';
import SelectAllCheckbox from './SelectAllCheckbox';
import {
  Asset, RoleOrganization,
  useCreateRegisteredSensorAssetMutation,
  useDeleteRegisteredSensorAssetMutation,
  useSearchRegisteredSensorAssetsLazyQuery,
} from '../../../generated/gql/types';
import {
  Direction, Filter, generateSearchString, Sort,
} from '../../hooks/useRegisteredSensorAssets';
import useMyRoles from '../../hooks/useMyRoles';
import { getProperty, RegisteredSensorAssetProperty } from '../../../AssetHelpers';
import UpdateStatusDropdown from './UpdateStatusDropdown';
import StatusBadge from './StatusBadge';
import ColumnSortButton from './ColumnSortButton';
import TableLoadingSkeleton from './TableLoadingSkeleton';
import FilterDropdown from './FilterDropdown';
import { WeCityOrganizationIdHeader } from '../../../Global';

function RegisteredSensorApp() {
  const params = useParams();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [sort, setSort] = useState<Sort>({ name: 'created_at', direction: Direction.DESC });
  const [toast, setToast] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfRows, setNumberOfRows] = useState<number | undefined>(10);
  const [filters, setFilters] = useState<Filter[]>([]);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [selectAllSensors, setSelectAllSensors] = useState(false);
  const [individualSensorOptions, setIndividualSensorOptions] = useState<Option[]>([]);
  const myRoles = useMyRoles();
  const orgIdRoles = (myRoles?.organizationRoles ?? []).find((or) => or.orgId === params.orgId)?.roles ?? [];

  const [searchAssets, {
    data: searchData, loading: searchLoading, error: searchError, refetch: searchRefetch,
  }] = useSearchRegisteredSensorAssetsLazyQuery({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  function clearSelectAllSensorsSelected() {
    if (selectAllSensors) {
      setSelectAllSensors(!selectAllSensors);
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  const searchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value.length >= 3) {
      setQuery(event.target.value);
    } else {
      setQuery(undefined);
    }
  };

  const debouncedSearchChangeHandler = useCallback(
    debounce(searchChangeHandler, 500),
    [],
  );

  const clearQuery = () => {
    setQuery('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const [createAsset, {
    error: duplicateAssetError,
  }] = useCreateRegisteredSensorAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const paginateNext = () => setCurrentPage(currentPage + 1);
  const paginatePrevious = () => setCurrentPage(currentPage - 1);
  const paginateToPage = (page: number) => setCurrentPage(page);

  const numberOfRowsChangeHandler = (value: number | undefined) => {
    setNumberOfRows(value);
  };

  useEffect(() => {
    setIndividualSensorOptions([]);
    searchAssets({
      variables: {
        searchQuery: `?${generateSearchString(numberOfRows ?? 10, currentPage, query, sort, filters)}`,
      },
    });
  }, [currentPage, query, numberOfRows, sort, filters]);

  const assets = searchData?.searchRegisteredSensorAssets?.data;

  function sensorIdsToOptionList(assetsToOption: Asset[]): Option[] {
    return assetsToOption.map((item, index) => (
      {
        name: '',
        index,
        value: item.id,
        checked: false,
      } as Option
    ));
  }

  useEffect(() => {
    if (!assets || searchLoading) {
      return;
    }
    clearSelectAllSensorsSelected();
    setIndividualSensorOptions(sensorIdsToOptionList(assets));
  }, [searchLoading, assets]);

  function clearToast() {
    window.history.replaceState({}, document.title);
  }

  function refreshTable(toastMessage?: string) {
    setIndividualSensorOptions([]);
    searchRefetch();
    if (toastMessage) {
      setToast(toastMessage);
    }
  }

  const handleSelectAllChange = () => {
    setIndividualSensorOptions(individualSensorOptions.map((option) => ({ ...option, checked: !selectAllSensors })));
    setSelectAllSensors(!selectAllSensors);
  };

  const [deleteAssetQuery, {
    error: deleteError,
  }] = useDeleteRegisteredSensorAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  function deleteAssets(assetIds: string[]) {
    Promise.all(assetIds.map((id) => (
      deleteAssetQuery({
        variables: {
          assetId: id,
        },
      })
    ).then((_) => {
      refreshTable(`${t('asset deleted successfully')}`);
    })));
  }

  function duplicateAsset(asset: Asset) {
    createAsset({
      variables: {
        command: {
          name: `${asset.name} *`,
          properties: asset.properties,
        },
      },
    }).then((_) => {
      refreshTable(`${t('asset duplicated successfully')}`);
    });
  }

  function handleDeleteConfirmation(assetsIds: string[]) {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete these sensors')}`)) {
      deleteAssets(assetsIds);
    }
  }

  function formatStringDate(date: string | undefined) {
    if (!date) {
      return 'unknown';
    }

    const parsedDate = parse(date, 'y-MM-dd', new Date());

    if (isValid(parsedDate)) {
      return format(parsedDate, 'dd-MM-y');
    }

    return 'unknown';
  }

  function renderTable() {
    if (searchError) {
      return (
        <Table.Row>
          <Table.Cell>
            {searchError.message}
          </Table.Cell>
        </Table.Row>
      );
    }

    if (searchLoading || !assets) {
      return (
        <TableLoadingSkeleton rows={numberOfRows ?? 10} />
      );
    }

    if (assets.length <= 0 || individualSensorOptions.length <= 0) {
      return (
        <Table.Row>
          <Table.Cell>
            {t('no search results found')}
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      assets?.map((asset) => (
        <Table.Row
          key={SRAssetRoute(params.orgId, asset.id)}
          className="bg-white dark:border-gray-700 dark:bg-gray-800"
        >
          <Table.Cell>
            <AssetCheckbox
              option={individualSensorOptions.find((option) => option.value === asset.id)!}
              onChange={(selectedOption: Option) => {
                setIndividualSensorOptions(individualSensorOptions.map(
                  (listOption) => (listOption.index === selectedOption.index
                    ? selectedOption : listOption),
                ));
              }}
            />
          </Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            {asset.name}
          </Table.Cell>
          <Table.Cell>
            {t(getProperty(asset, RegisteredSensorAssetProperty.SensorType)?.value ?? 'unknown')}
          </Table.Cell>
          <Table.Cell>
            {t((getProperty(asset, RegisteredSensorAssetProperty.Theme)?.value ?? 'unknown').replaceAll('_', ' '))}
          </Table.Cell>
          <Table.Cell>
            {formatStringDate(getProperty(asset, RegisteredSensorAssetProperty.ValidUntil)?.value!)}
          </Table.Cell>
          <Table.Cell>
            <StatusBadge status={getProperty(asset, RegisteredSensorAssetProperty.Status)?.value ?? 'unknown'} />
          </Table.Cell>
          <Table.Cell>
            {getProperty(asset, RegisteredSensorAssetProperty.SensorReferenceId)?.value ?? 'unknown'}
          </Table.Cell>
          <Table.Cell>
            {format(parseISO(asset.updatedAt), 'dd-MM-y', { locale: nl })}
          </Table.Cell>
          {orgIdRoles.find((role) => role === RoleOrganization.OrgSensorRegisterManager) ? (
            <Table.Cell>
              <Link className="mx-4" to={SRAssetRoute(params.orgId, asset.id)}>
                <FontAwesomeIcon icon={faEdit} color="gray" />
              </Link>
            </Table.Cell>
          ) : (
            <Table.Cell>
              <Link className="mx-4" to={SRAssetRoute(params.orgId, asset.id)}>
                <FontAwesomeIcon icon={faEye} color="gray" />
              </Link>
            </Table.Cell>
          )}
        </Table.Row>
      ))
    );
  }

  function renderSortableTableHeadCell(sortablePropertyName: string) {
    return (
      <Table.HeadCell>
        {sort.name === sortablePropertyName ? (
          <div className="flex items-center gap-x-2 whitespace-nowrap underline">
            {t(sortablePropertyName)}
            <ColumnSortButton
              sort={{
                name: sortablePropertyName,
                direction: sort.name === sortablePropertyName ? sort.direction : Direction.DESC,
              }}
              onChange={setSort}
            />
          </div>
        ) : (
          <div className="flex items-center gap-x-2 whitespace-nowrap">
            {t(sortablePropertyName)}
            <ColumnSortButton
              sort={{
                name: sortablePropertyName,
                direction: sort.name === sortablePropertyName ? sort.direction : Direction.DESC,
              }}
              onChange={setSort}
            />
          </div>
        )}

      </Table.HeadCell>
    );
  }

  function renderDeleteButton() {
    const ids = individualSensorOptions.filter((option) => option.checked).map((option) => option.value);

    if (ids.length > 0) {
      return (
        <Button
          type="button"
          className="btn inline-flex items-center rounded-lg py-1 text-center text-sm font-medium focus:outline-none focus:ring-4 hover:bg-wc-hover-blue"
          onClick={() => handleDeleteConfirmation(ids)}
        >
          {t('delete sensor')}
        </Button>
      );
    }

    return (
      <Button
        type="button"
        disabled
        className="btn inline-flex items-center rounded-lg py-1 text-center text-sm font-medium focus:outline-none focus:ring-4 disabled:pointer-events-none"
      >
        {t('delete sensor')}
      </Button>
    );
  }

  function renderSearchBar() {
    return (
      <div className="mt-4 inline space-x-3 lg:mt-6 justify-start">
        <div className="left-0 flex items-center max-w-md">
          <TextInput
            className="left-0 flex-grow border border-none"
            placeholder={t('search sensors') ?? 'search'}
            ref={inputRef}
            onChange={debouncedSearchChangeHandler}
          />
          <FontAwesomeIcon icon={faMagnifyingGlass} color="gray" className="relative -left-8" />
          <button
            type="button"
            onClick={clearQuery}
            className="relative rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <FontAwesomeIcon icon={faXmark} color="gray" />
          </button>
        </div>
      </div>
    );
  }

  function getSelectedAssets(): Asset[] {
    const selectedIds = individualSensorOptions.filter((option) => option.checked).map((option) => option.value);

    return assets?.filter((asset) => selectedIds.find((id) => id === asset.id)) ?? [];
  }

  function renderDuplicateButton() {
    const selectedAssets = getSelectedAssets();

    if (selectedAssets.length === 1) {
      return (
        <Button
          type="button"
          className="btn inline-flex items-center rounded-lg py-1 text-center text-sm font-medium focus:outline-none focus:ring-4 hover:bg-wc-hover-blue"
          onClick={() => duplicateAsset(selectedAssets[0])}
        >
          {t('duplicate sensor')}
        </Button>
      );
    }

    return (
      <Button
        type="button"
        disabled
        className="btn inline-flex items-center rounded-lg py-1 text-center text-sm font-medium focus:outline-none focus:ring-4 disabled:pointer-events-none"
      >
        {t('duplicate sensor')}
      </Button>
    );
  }

  function renderUpdateStatusButton() {
    const selectedAssets = getSelectedAssets();

    return (
      <UpdateStatusDropdown
        selectedAssets={selectedAssets}
        updateCompleteHandler={() => refreshTable(`${t('asset status updated successfully')}`)}
      />
    );
  }

  function renderFilterDropdown() {
    return (
      <FilterDropdown
        selectedFilters={filters}
        onChangeHandler={(value) => setFilters(value.map((m) => m.value))}
      />
    );
  }

  return (
    <div>
      <div className="flex flex-col mx-auto">
        <div className="upper row grid grid-col-2 py-4">
          <div className="grid grid-cols-2 py-4">
            <h4
              className="mb-5 text-3xl text-gray-900 "
            >
              {query ? (
                <>
                  {t('found sensors for search', { total: searchData?.searchRegisteredSensorAssets?.meta?.total })}
                  {' '}
                  <span className="font-bold inline">
                    {`"${query}"`}
                  </span>
                </>
              ) : (
                <>
                  {t('available sensors', { total: searchData?.searchRegisteredSensorAssets?.meta?.total })}
                </>
              )}

            </h4>
            <div className="inline justify-self-end">
              {state?.toastCreated && (
                <Toast onClick={() => clearToast()}>
                  <div
                    className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
                  >
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    {t('asset created successfully')}
                  </div>
                  <Toast.Toggle />
                </Toast>
              )}

              {state?.toastChanged && (
                <Toast onClick={() => clearToast()}>
                  <div
                    className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
                  >
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    {t('asset changed successfully')}
                  </div>
                  <Toast.Toggle />
                </Toast>
              )}

              {toast && (
                <Toast onClick={() => setToast(undefined)}>
                  <div
                    className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
                  >
                    <FontAwesomeIcon icon={faCheck} color="green" />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    {toast}
                  </div>
                  <Toast.Toggle />
                </Toast>
              )}

              {(deleteError || duplicateAssetError) && (
                <Toast>
                  <div
                    className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200"
                  >
                    <FontAwesomeIcon icon={faXmark} color="red" />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    {t('something went wrong')}
                  </div>
                  <Toast.Toggle />
                </Toast>
              )}
            </div>
          </div>
          <div className="grid grid-col-3 grid-flow-col gap-4">
            {renderSearchBar()}
            {renderFilterDropdown()}
            <div className="inline justify-self-end">
              {orgIdRoles.find((role) => role === RoleOrganization.OrgSensorRegisterManager) ? (
                <div className="mt-4 flex space-x-3 lg:mt-6">
                  <Link
                    type="button"
                    className="btn inline-flex items-center rounded-lg px-4 py-1 text-center text-sm font-medium"
                    to={SRAssetCreateRoute(params.orgId)}
                  >
                    {t('new sensor')}
                  </Link>
                  {renderDeleteButton()}
                  {renderDuplicateButton()}
                  {renderUpdateStatusButton()}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <Table>
          <Table.Head>
            <Table.HeadCell>
              <SelectAllCheckbox isChecked={selectAllSensors} onChange={handleSelectAllChange} />

              <span className="sr-only">
                Checkbox
              </span>
            </Table.HeadCell>
            {renderSortableTableHeadCell('name')}
            <Table.HeadCell>
              {t('type')}
            </Table.HeadCell>
            <Table.HeadCell>
              {t('theme')}
            </Table.HeadCell>
            {renderSortableTableHeadCell('valid_until')}
            {renderSortableTableHeadCell('status')}
            {renderSortableTableHeadCell('sensor_reference_id')}
            {renderSortableTableHeadCell('updated_at')}
            <Table.HeadCell>
              {t('edit')}
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {renderTable()}
          </Table.Body>
        </Table>

        <Pagination
          paginationMeta={searchData?.searchRegisteredSensorAssets?.meta}
          paginateNext={paginateNext}
          paginatePrevious={paginatePrevious}
          paginateToPage={paginateToPage}
          numberOfRows={numberOfRows}
          onNumberOfRowsChange={numberOfRowsChangeHandler}
        />
        {process.env.REACT_APP_SENSORREGISTER_IMPORT_ENABLED === 'true' && (
          <div className="mt-4 flex space-x-3 lg:mt-6">
            <Link
              type="button"
              className="btn inline-flex items-center rounded-lg px-4 py-2 text-center text-sm font-medium"
              to={SRAssetImportRoute(params.orgId)}
            >
              {t('import spreadsheet')}
            </Link>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default RegisteredSensorApp;
