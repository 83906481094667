import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Label, Table, TextInput,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import { SingleValue } from 'react-select/dist/declarations/src/types';
import {
  CreateSensorRegistrationTokensCommand,
  useCreateSensorRegistrationTokensMutation,
  useGetHubspotContactListsQuery, useGetOrganizationsQuery,
} from '../../../generated/gql/types';
import Loader from '../../Loader';

function CreateSensorRegistrationTokensApp() {
  const { data: organizationsData, loading: organizationsLoading } = useGetOrganizationsQuery();
  const { register, handleSubmit, setValue } = useForm<CreateSensorRegistrationTokensCommand>();

  const [createTokens, { data, loading, error }] = useCreateSensorRegistrationTokensMutation();
  const {
    data: contactLists,
    loading: contactListsLoading,
    error: contactListsError,
  } = useGetHubspotContactListsQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();

  const onSubmit = handleSubmit((createTokensCommand) => {
    if (createTokensCommand.listId === '0') {
      return;
    }

    createTokens({
      variables: {
        command: createTokensCommand,
      },
    });
  });

  function setContactList(e: SingleValue<{ label: string, value: number }>) {
    if (e) {
      setValue('listId', e.value.toString());
    }
  }

  function setOrganization(e: SingleValue<{ label: string, value: string }>) {
    if (e) {
      setValue('orgId', e.value);
    }
  }

  if (contactListsLoading || organizationsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full md:w-1/2 text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          {t('hubspot management')}
        </h2>

        <h3 className="mb-4 text-3xl w-full md:w-1/2">{t('create sensor registration tokens')}</h3>
        <p>
          {t('create sensor registration tokens description')}
        </p>
        <a className="text-wc-blue underline" href="https://app.hubspot.com/contacts/8659463/objectLists/all" target="_blank" rel="noreferrer">Hubspot lists page</a>
      </div>

      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <div>
          {contactLists && contactLists?.hubspot?.contactLists?.length > 0 && !contactListsError ? (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="listId"
                  value={`${t('select contact list')}`}
                />
              </div>
              <Select
                required
                isSearchable
                placeholder="Search contact list"
                isLoading={loading}
                options={contactLists?.hubspot?.contactLists?.map((l) => ({
                  label: `${l.name} (${l.contactCount})`,
                  value: l.id,
                }))}
                className="w-full"
                classNamePrefix="select"
                onChange={(e) => setContactList(e)}
              />
            </>
          ) : (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="listId"
                  value={`${t('no list found')}`}
                />
              </div>
              <TextInput
                type="text"
                placeholder="12345"
                required
                {...register('listId')}
              />
            </>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="orgId"
              value={`${t('select organization')}`}
            />
          </div>
          <Select
            required
            isSearchable
            placeholder="Search organization"
            isLoading={loading}
            options={organizationsData?.organizations?.map((o) => ({
              label: o.orgId,
              value: o.orgId,
            }))}
            className="w-full"
            classNamePrefix="select"
            onChange={(e) => setOrganization(e)}
          />
        </div>

        {loading ? (
          <Button type="submit" disabled>
            Create tokens
          </Button>
        ) : (
          <Button type="submit">
            Create tokens
          </Button>
        )}
      </form>

      { loading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          {(error && (
            <div className="py-4">
              <p className="font-bold">Oops something went wrong: </p>
              <p>{error.message}</p>
            </div>
          ))}

          {((data && data.hubspot.createSensorRegistrationTokens.errorMessages.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">Errors</h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    Message
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  { data.hubspot.createSensorRegistrationTokens.errorMessages.map((message) => (
                    <Table.Row key={message} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {message}
                      </Table.Cell>
                    </Table.Row>
                  )) }
                </Table.Body>
              </Table>
            </>
          ))}

          {((data && data.hubspot.createSensorRegistrationTokens.createdTokensForUsers.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">
                Created tokens for user (
                {data.hubspot.createSensorRegistrationTokens.createdTokensForUsers.length}
                )
              </h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    {t('first name')}
                  </Table.HeadCell>
                  <Table.HeadCell>
                    {t('last name')}
                  </Table.HeadCell>
                  <Table.HeadCell>
                    {t('email')}
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  { data.hubspot.createSensorRegistrationTokens.createdTokensForUsers.map((user) => (
                    <Table.Row key={user.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {user.firstname}
                      </Table.Cell>
                      <Table.Cell>
                        {user.lastname}
                      </Table.Cell>
                      <Table.Cell>
                        {user.email}
                      </Table.Cell>
                    </Table.Row>
                  )) }
                </Table.Body>
              </Table>
            </>
          ))}

          {(data && data.hubspot.createSensorRegistrationTokens.createdTokensForUsers.length <= 0 && (
            <p className="py-4">No tokens have been created</p>
          ))}
        </div>
      ) }
    </div>
  );
}

export default CreateSensorRegistrationTokensApp;
