import React from 'react';
import { Navbar } from 'flowbite-react';
import { Link } from 'react-router-dom';
import logo from '../images/wecity_logo_main.png';
import Profile from './Profile';
import { DashboardRoute } from './Routes';

function Navigation({ hasProfile } : NavigationProps) {
  return (
    <Navbar
      fluid
      rounded
      className="mt-5 pb-5 mb-5 border-b border-slate-500"
    >
      <Link to={DashboardRoute()}>
        <img src={logo} alt="Logo" className="mr-3 h-6 sm:h-9" />
      </Link>

      {hasProfile ? (
        <div className="flex md:order-2 flex-wrap gap-2">
          <Profile />
        </div>
      ) : ''}

    </Navbar>
  );
}

type NavigationProps = {
  hasProfile: React.ReactNode
};

export default Navigation;
