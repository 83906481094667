import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

function SelectAllCheckbox({ isChecked, onChange }: any) {
  return (
    <button
      type="button"
      className="flex flex-row gap-x-2 items-center cursor-pointer"
      onClick={() => onChange()}
    >
      {isChecked ? (
        <div className="w-5 h-5 flex border border-wc-blue rounded bg-wc-blue text-white items-center justify-center">
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ) : (
        <div className="w-5 h-5 border border-gray-400 rounded" />
      )}

      <div className="flex items-center flex-row capitalize-first select-none space-x-1">{' '}</div>
    </button>
  );
}

export default SelectAllCheckbox;
