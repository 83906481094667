import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Table, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import {
  format, parseISO,
} from 'date-fns';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { nl } from 'date-fns/locale';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import {
  useAdminSearchAssetsLazyQuery, useGetOrganizationsQuery,
} from '../../../generated/gql/types';
import { Filter, generateSearchString } from '../../hooks/useRegisteredSensorAssets';
import Pagination from '../registered-sensor/Pagination';
import Footer from '../../Footer';
import TableLoadingSkeleton from '../registered-sensor/TableLoadingSkeleton';
import { getProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import { UserAssetsRoute, UserRolesRoute, UserRoute } from '../../Routes';

function AssetManagementApp() {
  const numberOfRows = 25;
  const [organizationFilter, setOrganizationFilter] = useState<Filter | undefined>(undefined);
  const [serialIdFilter, setSerialIdFilter] = useState<Filter | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const clearQuery = () => {
    setSerialIdFilter(undefined);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const searchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value.length >= 3) {
      setSerialIdFilter({ key: 'sensor_serial_id', value: event.target.value } as Filter);
    } else {
      setSerialIdFilter(undefined);
    }
  };

  const debouncedSearchChangeHandler = useCallback(
    debounce(searchChangeHandler, 500),
    [],
  );

  const { data: organizationsData, loading: organizationsLoading } = useGetOrganizationsQuery();

  const [searchAssets, {
    data: searchData, loading: searchLoading, error: searchError,
  }] = useAdminSearchAssetsLazyQuery({
    context: {
      clientName: 'asset',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    let filters: Filter[] = [];

    if (organizationFilter) {
      filters = [...filters, organizationFilter];
    }
    if (serialIdFilter) {
      filters = [...filters, serialIdFilter];
    }

    searchAssets({
      variables: {
        searchQuery: `?${generateSearchString(numberOfRows, currentPage, undefined, undefined, filters)}`,
      },
    });
  }, [currentPage, serialIdFilter, organizationFilter]);

  const paginateNext = () => setCurrentPage(currentPage + 1);
  const paginatePrevious = () => setCurrentPage(currentPage - 1);
  const paginateToPage = (page: number) => setCurrentPage(page);

  function renderTable() {
    const assets = searchData?.adminSearchAssets?.data;

    if (searchError) {
      return (
        <Table.Row>
          <Table.Cell>
            {searchError.message}
          </Table.Cell>
        </Table.Row>
      );
    }

    if (searchLoading || !assets) {
      return (
        <TableLoadingSkeleton rows={numberOfRows} />
      );
    }

    if (assets.length <= 0) {
      return (
        <Table.Row>
          <Table.Cell>
            {t('no search results found')}
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      assets?.map((asset) => (
        <Table.Row
          key={asset.id}
          className="bg-white dark:border-gray-700 dark:bg-gray-800"
        >
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            {asset.name}
          </Table.Cell>
          <Table.Cell>
            {t(getProperty(asset, PientereTuinenAssetProperty.SensorId)?.value ?? 'unknown')}
          </Table.Cell>
          <Table.Cell>
            {t(getProperty(asset, PientereTuinenAssetProperty.OrganizationId)?.value ?? 'unknown')}
          </Table.Cell>
          <Table.Cell>
            {getProperty(asset, PientereTuinenAssetProperty.SoilType)?.value ?? 'unknown'}
          </Table.Cell>
          <Table.Cell>
            {getProperty(asset, PientereTuinenAssetProperty.Coordinates) ? (
              <Link
                target="_blank"
                className="underline text-wc-blue"
                to={`https://maps.google.com/maps?t=k&q=loc:${getProperty(asset, PientereTuinenAssetProperty.Coordinates)?.value ?? ''}`}
              >
                {getProperty(asset, PientereTuinenAssetProperty.Coordinates)?.value ?? ''}
              </Link>
            ) : 'unknown'}
          </Table.Cell>
          <Table.Cell>
            {format(parseISO(asset.createdAt), 'dd-MM-y HH:mm:ss', { locale: nl })}
          </Table.Cell>
          <Table.Cell>
            {getProperty(asset, PientereTuinenAssetProperty.UserId) ? (
              <div className="flex gap-x-4">
                <Link to={UserRoute(getProperty(asset, PientereTuinenAssetProperty.UserId)?.value ?? '')}>
                  <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-edit' })} />
                </Link>
                <Link to={UserRolesRoute(getProperty(asset, PientereTuinenAssetProperty.UserId)?.value ?? '')}>
                  <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-tag' })} />
                </Link>
                <Link to={UserAssetsRoute(getProperty(asset, PientereTuinenAssetProperty.UserId)?.value ?? '')}>
                  <FontAwesomeIcon className="cursor-pointer" size="xl" icon={icon({ name: 'user-chart' })} />
                </Link>
              </div>
            ) : 'Not linked'}
          </Table.Cell>
        </Table.Row>
      ))
    );
  }

  return (
    <div>
      <div className="flex flex-col mx-auto">
        <div className="upper row grid grid-col-2 py-4">
          <div className="grid grid-cols-2 py-4">
            <h4 className="mb-5 text-3xl text-gray-900 ">
              {t('available sensors', { total: searchData?.adminSearchAssets?.meta?.total })}
            </h4>
          </div>
          <div className="grid grid-col-3 grid-flow-col gap-4">
            <div className="left-0 flex items-center max-w-md">
              <TextInput
                className="left-0 flex-grow border border-none"
                placeholder="Serial id"
                ref={inputRef}
                onChange={debouncedSearchChangeHandler}
              />
              <FontAwesomeIcon icon={faMagnifyingGlass} color="gray" className="relative -left-8" />
              <button
                type="button"
                onClick={clearQuery}
                className="relative rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <FontAwesomeIcon icon={faXmark} color="gray" />
              </button>
            </div>
            <Select
              required
              isClearable
              isSearchable
              placeholder="Select organization"
              isLoading={organizationsLoading}
              options={organizationsData?.organizations?.map((o) => ({
                label: o.orgId,
                value: o.orgId,
              }))}
              className="w-full"
              classNamePrefix="select"
              onChange={(e) => (e != null ? setOrganizationFilter({
                key: PientereTuinenAssetProperty.OrganizationId,
                value: e.value,
              } as Filter) : setOrganizationFilter(undefined))}
            />
          </div>
        </div>
        <Table>
          <Table.Head>
            <Table.HeadCell>
              Name
            </Table.HeadCell>
            <Table.HeadCell>
              Serial id
            </Table.HeadCell>
            <Table.HeadCell>
              Organization
            </Table.HeadCell>
            <Table.HeadCell>
              Soil type
            </Table.HeadCell>
            <Table.HeadCell>
              Coordinates
            </Table.HeadCell>
            <Table.HeadCell>
              Created at
            </Table.HeadCell>
            <Table.HeadCell>
              Linked to user
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {renderTable()}
          </Table.Body>
        </Table>

        <Pagination
          paginationMeta={searchData?.adminSearchAssets?.meta}
          paginateNext={paginateNext}
          paginatePrevious={paginatePrevious}
          paginateToPage={paginateToPage}
          numberOfRows={numberOfRows}
          onNumberOfRowsChange={undefined}
        />
      </div>
      <Footer />
    </div>
  );
}

export default AssetManagementApp;
