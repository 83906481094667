import React from 'react';
import { Table } from 'flowbite-react';

function TableLoadingSkeleton({ rows }: TableLoadingSkeletonProps) {
  return (
    <>
      {Array.from(Array(rows).keys()).map((i) => (
        <Table.Row key={`skelr-${i}`} className="bg-white dark:border-gray-700 dark:bg-gray-800 animate-pulse">
          <Table.Cell key="skelc-1">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-5" />
          </Table.Cell>
          <Table.Cell key="skelc-2">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 whitespace-nowrap font-medium w-40" />
          </Table.Cell>
          <Table.Cell key="skelc-3">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-32" />
          </Table.Cell>
          <Table.Cell key="skelc-4">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700" />
          </Table.Cell>
          <Table.Cell key="skelc-5">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-20" />
          </Table.Cell>
          <Table.Cell key="skelc-6">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-20" />
          </Table.Cell>
          <Table.Cell key="skelc-7">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-24" />
          </Table.Cell>
          <Table.Cell key="skelc-8">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-20" />
          </Table.Cell>
          <Table.Cell key="skelc-9">
            <div className="bg-gray-200 h-5 rounded-md dark:bg-gray-700 w-8" />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
}

type TableLoadingSkeletonProps = {
  rows: number
};

export default TableLoadingSkeleton;
