import React from 'react';
import { Alert } from 'flowbite-react/lib/esm/components/Alert/Alert';
import { useGetPlatformAlertsQuery } from './generated/gql/types';
import { ReactComponent as InfoSvg } from './images/info.svg';

function PlatformAlerts() {
  const { data } = useGetPlatformAlertsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <div>
      {data?.platformAlerts.map((alert) => (
        <div className="py-2" key={alert.id}>
          <Alert
            color="failure"
            icon={InfoSvg}
          >
            <span>
              <span className="font-bold pr-2">
                Let op:
              </span>
              {alert.message}
            </span>
          </Alert>
        </div>
      ))}
    </div>
  );
}

export default PlatformAlerts;
