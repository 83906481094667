import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Label, Select, Table, TextInput,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { useGetHubspotContactListsQuery, useSendVerificationEmailMutation } from '../../../generated/gql/types';
import Loader from '../../Loader';

function SentVerificationEmailApp() {
  const { register, handleSubmit } = useForm<SendVerificationEmailForm>();
  const [sendVerificationEmail, { data, loading, error }] = useSendVerificationEmailMutation();
  const { t } = useTranslation();
  const {
    data: contactLists,
    loading: contactListsLoading,
    error: contactListsError,
  } = useGetHubspotContactListsQuery({ fetchPolicy: 'network-only' });

  const onSubmit = handleSubmit((form) => {
    if (form.hubspotContactListId === '0') {
      return;
    }

    sendVerificationEmail({
      variables: {
        hubspotContactListId: form.hubspotContactListId,
      },
    });
  });

  if (contactListsLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-center mx-auto max-w-4xl">
      <div className="py-4">
        <h2
          className="mb-5 w-full md:w-1/2 text-4xl tracking-tight font-extrabold text-gray-900 "
        >
          {t('hubspot management')}
        </h2>

        <h3 className="mb-4 text-3xl w-full md:w-1/2">Send verification emails</h3>
        <p>
          {t('sent verification emaildescription')}
        </p>
        <a className="text-wc-blue underline" href="https://app.hubspot.com/contacts/8659463/objectLists/all" target="_blank" rel="noreferrer">Hubspot lists page</a>
      </div>

      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <div>
          {contactLists && contactLists?.hubspot?.contactLists?.length > 0 && !contactListsError ? (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="hubspotContactListId"
                  value="Select a contact list"
                />
              </div>
              <Select
                required
                {...register('hubspotContactListId', {
                  value: '0',
                  required: true,
                })}
              >
                <option value="0" disabled>Select a contact list</option>
                {contactLists?.hubspot?.contactLists?.map(
                  (list) => (
                    <option key={list.id} value={list.id}>
                      {list.name}
                      {'\u00A0'}
                      (
                      {list.contactCount}
                      )
                    </option>
                  ),
                )}
              </Select>
            </>
          ) : (
            <>
              <div className="mb-2 block">
                <Label
                  htmlFor="listId"
                  value="No hubspot contact lists found, please provide a contact list id"
                />
              </div>
              <TextInput
                type="text"
                placeholder="12345"
                required
                {...register('hubspotContactListId')}
              />
            </>
          )}
        </div>
        {loading ? (
          <Button type="submit" disabled>
            Import
          </Button>
        ) : (
          <Button type="submit">
            Import
          </Button>
        )}

      </form>

      {loading ? (
        <div className="p-5">
          <Loader />
        </div>
      ) : (
        <div className="py-2">
          {(error && (
            <div className="py-4">
              <p className="font-bold">Oops something went wrong: </p>
              <p>{error.message}</p>
            </div>
          ))}

          {((data && data.hubspot.sendVerificationEmails.length > 0) && (
            <>
              <h2 className="py-4 text-2xl font-bold">
                Sent emails (
                {data.hubspot.sendVerificationEmails.length}
                )
              </h2>
              <Table hoverable>
                <Table.Head>
                  <Table.HeadCell>
                    Message
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {data.hubspot.sendVerificationEmails.map((message: string) => (
                    <Table.Row key={message} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell>
                        {message}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          ))}

          {(data && data.hubspot.sendVerificationEmails.length <= 0 && (
            <p className="py-4">No emails have been send</p>
          ))}
        </div>
      )}

    </div>
  );
}

type SendVerificationEmailForm = {
  hubspotContactListId: string
};

export default SentVerificationEmailApp;
