import { min } from 'lodash';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { usePaginateRegisteredSensorAssetsQuery } from '../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../Global';

export enum Direction {
  ASC,
  DESC,
}
export function generateSearchString(perPage: number, page: number, query: string | undefined, sort: Sort | undefined, filters: Filter[] = []): string {
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append('query', query);
  }

  if (sort) {
    searchParams.append('sort', sort.direction === Direction.DESC ? `-${sort.name}` : sort.name);
  }

  const groupedFilters = new Map();
  filters.forEach((filter) => {
    if (!groupedFilters.has(filter.key)) {
      groupedFilters.set(filter.key, [filter.value]);
    } else {
      groupedFilters.set(filter.key, [...groupedFilters.get(filter.key), filter.value]);
    }
  });
  groupedFilters.forEach((value, key) => {
    searchParams.append(`filter[${key}]`, value.join(','));
  });

  searchParams.append('per_page', (min([perPage ?? 24, 100]) ?? 24).toString());
  searchParams.append('page', (page > 0 ? page : 1).toString());

  return searchParams.toString();
}

export function generateFilterString(filters: Filter[], perPage: number, page: number): string {
  const searchParams = new URLSearchParams();

  const groupedFilters = new Map();
  filters.forEach((filter) => {
    if (!groupedFilters.has(filter.key)) {
      groupedFilters.set(filter.key, [filter.value]);
    } else {
      groupedFilters.set(filter.key, [...groupedFilters.get(filter.key), filter.value]);
    }
  });

  groupedFilters.forEach((value, key) => {
    searchParams.append(`filter[${key}]`, value.join(','));
  });

  searchParams.append('per_page', (min([perPage ?? 24, 100]) ?? 24).toString());
  searchParams.append('page', (page > 0 ? page : 1).toString());

  return searchParams.toString();
}

function useRegisteredSensorAssets({
  orgId, filters, perPage, page,
} : AssetProps) {
  const { loading, error, data } = usePaginateRegisteredSensorAssetsQuery({
    variables: {
      filterQuery: `?${generateFilterString(filters, perPage ?? 10, page ?? 1)}`,
    },
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error !== null && error !== undefined) {
      Sentry.captureMessage(error.message, 'error');
    }
  }, [error]);

  return { data, loading, error: (error ? error?.message : undefined) };
}

export type Filter = {
  key: string
  value: string
};

export type Sort = {
  name: string
  direction: Direction
};

type AssetProps = {
  orgId: string
  filters: Filter[]
  searchQuery?: string
  perPage?: number
  page?:number
};

export default useRegisteredSensorAssets;
